import { Dispatch, useState } from "react";
import {
  SalesActivityDetailInfo,
  SalesActivityInfo,
} from "../../../../../system/types/SalesActivity";
import { SalesActivityApi } from "../../../../../system/api/SalesActivityApi";
import {
  ActivitySectionStatesInfo,
  LeadCustomerDetailInfo,
} from "../../../../../system/types/Customer";
import { Box, Grid, Modal } from "@mui/material";
import { CustomText } from "../../../../../styles/CustomText";
import {
  BigModalStyle,
  LeadStepperBox,
  PointerItem,
} from "../../../../../styles/theme";
import {
  CustomButtonGreen,
  CustomButtonYellow,
} from "../../../../../styles/ButtonStyle";
import { ErrorHandler } from "../../../../../system/ApiService";
import AlertModal from "../../../../../components/Common/AlertModal";

import { X } from "@phosphor-icons/react";
import { InitSalesActivityInfo } from "../../../../../system/types/initObject";
import ActivityInfo from "./ActivityInfo";
import ActivityCreate from "./ActivityCreate";
import ActivityPrevious from "./ActivityPrevious";
import ActivityContent from "./ActivityContent";
import { isoDateFormatter } from "../../../../../components/Common/IsoDateFormatter";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import SendIcon from "@mui/icons-material/Send";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import ActivityComment from "./ActivityComment";

interface LeadWorkHistoryProps {
  data: LeadCustomerDetailInfo | null;
  refreshActivityData: () => void;
  activityDataList: SalesActivityDetailInfo[];
  setActivityDataList: (activityDataList: SalesActivityDetailInfo[]) => void;
  sortedState: string;
  setSortedState: Dispatch<React.SetStateAction<string>>;
}
function validation(data: SalesActivityInfo) {
  if (data.title === "") {
    AlertModal("check", "제목을 작성해 주세요.");
    return false;
  } else if (data.type === "") {
    AlertModal("check", "유형을 작성해 주세요.");
    return false;
  } else if (data.activityDate === "") {
    AlertModal("check", "작업일을 작성해 주세요.");
    return false;
  } else if (data.followUpDate === "") {
    AlertModal("check", "후속 조치 일자를 작성해 주세요.");
    return false;
  } else if (data.content === "") {
    AlertModal("check", "내용을 작성해 주세요.");
    return false;
  } else if (data.nextAction === "") {
    AlertModal("check", "다음 작업을 작성해 주세요.");
    return false;
  }
  return true;
}

function LeadWorkHistory({
  data,
  refreshActivityData,
  activityDataList,
  setActivityDataList,
  sortedState,
  setSortedState,
}: LeadWorkHistoryProps) {
  const [selectedActivityData, setSelectedActivityData] =
    useState<SalesActivityDetailInfo | null>(null);
  // create, edit, detail
  const [activityModalState, setActivityModalState] = useState("");
  const [open, setOpen] = useState(false);

  const EditModalOpen = () => {
    setOpen(true);
    setActivityModalState("create");
  };

  const DetailModalOpen = (dt: SalesActivityDetailInfo) => {
    if (dt) {
      setSelectedActivityData(dt);
      setOpen(true);
      setActivityModalState("detail");
      setSelectedSalesActivity(dt);
      setSalesActivityData({
        ...salesActivityData,
        priority: dt.priority,
        type: dt.type,
        status: dt.status,
        title: dt.title,
        content: dt.content,
        nextAction: dt.nextAction,
        activityDate: dt.activityDate,
        followUpDate: dt.followUpDate,
      });
    }
  };
  const [selectedSalesActivity, setSelectedSalesActivity] =
    useState<SalesActivityDetailInfo | null>(null);
  const [salesActivityData, setSalesActivityData] = useState<SalesActivityInfo>(
    {
      ...InitSalesActivityInfo,
      customerId: data?.customer.id ? data?.customer.id : null,
      title: data?.customer.address.placeName
        ? data?.customer.address.placeName + "_" + isoDateFormatter(new Date())
        : "",
    }
  );

  const handleClose = () => {
    setOpen(false);
    setSelectedActivityData(null);
    setSelectedSalesActivity(null);
    setSalesActivityData({
      ...InitSalesActivityInfo,
      customerId: data?.customer.id ? data?.customer.id : null,
      title: data?.customer.address.placeName
        ? data?.customer.address.placeName + "_" + isoDateFormatter(new Date())
        : "",
    });
  };

  const [sectionStates, setSectionStates] = useState<ActivitySectionStatesInfo>(
    {
      ActivityInfo: false,
      ActivityPrevious: false,
      ActivityNext: false,
    }
  );

  const toggleSection = (title: keyof ActivitySectionStatesInfo) => {
    setSectionStates({
      ...sectionStates,
      [title]: !sectionStates[title],
    });
  };

  const onChangeEditState = () => {
    setActivityModalState("edit");
  };
  var submitcheck = false;
  const onSave = () => {
    if (validation(salesActivityData) && submitcheck === false) {
      submitcheck = true;
      if (selectedActivityData?.id) {
        SalesActivityApi.updateSalesActivity(
          selectedActivityData?.id,
          salesActivityData
        )
          .then(() => {
            AlertModal("success", "작업이력이 수정되었습니다.");
            setOpen(false);
            setSalesActivityData({
              ...InitSalesActivityInfo,
              customerId: data?.customer.id ? data?.customer.id : null,
              title: data?.customer.address.placeName
                ? data?.customer.address.placeName +
                  "_" +
                  isoDateFormatter(new Date())
                : "",
            });
            setSelectedActivityData(null);
            refreshActivityData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = false;
          });
      } else {
        SalesActivityApi.createSalesActivity({
          salesActivity: salesActivityData,
          changedcustomerStepId: null,
        })
          .then(() => {
            AlertModal("success", "작업이력이 생성되었습니다.");
            setOpen(false);
            setSalesActivityData({
              ...InitSalesActivityInfo,
              customerId: data?.customer.id ? data?.customer.id : null,
              title: data?.customer.address.placeName
                ? data?.customer.address.placeName +
                  "_" +
                  isoDateFormatter(new Date())
                : "",
            });
            refreshActivityData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = false;
          });
      }
    }
  };

  const sortData = (args: any) => {
    if (args.target.value) {
    }
    setSortedState(args.target.value);
  };

  return (
    <Grid container item xs={12} mt="20px">
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid>
          <DropDownListComponent
            dataSource={["내림차순", "오름차순"]}
            placeholder="작업일 기준"
            onChange={sortData}
            value={sortedState}
          />
          <CustomText type="body">(기준 : 작업일)</CustomText>
        </Grid>
        <CustomButtonGreen onClick={EditModalOpen}>
          작업 이력 생성
        </CustomButtonGreen>
      </Grid>
      {activityDataList.length > 0 ? (
        <LeadStepperBox item xs={12}>
          <Timeline>
            {activityDataList.map(
              (child: SalesActivityDetailInfo, index: number) => (
                <TimelineItem
                  key={index}
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  <TimelineOppositeContent
                    sx={{ marginTop: "15px" }}
                    color="textSecondary"
                  >
                    {child.activityDate}
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineDot variant="outlined">
                      {IconsComponent(child.type)}
                    </TimelineDot>
                    {index !== activityDataList.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>

                  <TimelineContent onClick={() => DetailModalOpen(child)}>
                    <CustomText
                      type="subtitle"
                      cursorPointer
                      lineSpace
                      style={{
                        overflow: "auto",
                        overflowWrap: "anywhere",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {child.title}
                    </CustomText>
                    <CustomText
                      type="body"
                      cursorPointer
                      lineSpace
                      style={{
                        overflow: "auto",
                        overflowWrap: "anywhere",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {child.content}
                    </CustomText>
                  </TimelineContent>
                </TimelineItem>
              )
            )}
          </Timeline>
        </LeadStepperBox>
      ) : (
        <Grid container item xs={12} justifyContent="center" mt="100px">
          <CustomText type="subtitle" bold400>
            조회된 작업이력이 없습니다.
          </CustomText>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={BigModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title">
                작업 이력 {activityModalState === "create" && "생성"}
                {activityModalState === "edit" && "수정"}
                {activityModalState === "detail" && "상세"}
              </CustomText>
              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid container item xs={12}>
              <ActivityInfo
                data={data}
                sectionStates={sectionStates}
                toggleSection={toggleSection}
              />
              {selectedActivityData?.previousActivity && (
                <ActivityPrevious
                  sectionStates={sectionStates}
                  toggleSection={toggleSection}
                  previousData={selectedActivityData?.previousActivity}
                  type="previous"
                />
              )}
              <ActivityCreate
                salesActivityData={salesActivityData}
                setSalesActivityData={setSalesActivityData}
                activityModalState={activityModalState}
              />
              {selectedActivityData?.nextActivity && (
                <ActivityPrevious
                  sectionStates={sectionStates}
                  toggleSection={toggleSection}
                  previousData={selectedActivityData?.nextActivity}
                  type="next"
                />
              )}
              <ActivityContent
                salesActivityData={salesActivityData}
                setSalesActivityData={setSalesActivityData}
                activityModalState={activityModalState}
              />
              <Grid container item xs={12} justifyContent="right" mt="20px">
                {activityModalState === "detail" ? (
                  <>
                    {/* <CustomButtonRed style={{ marginRight: "5px" }}>
                    삭제
                  </CustomButtonRed> */}
                    <CustomButtonYellow onClick={onChangeEditState}>
                      수정
                    </CustomButtonYellow>
                  </>
                ) : (
                  <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
                )}
              </Grid>
              {selectedSalesActivity && activityModalState === "detail" && (
                <ActivityComment
                  selectedSalesActivity={selectedSalesActivity}
                  refreshActivityData={refreshActivityData}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default LeadWorkHistory;

const IconsComponent = (type: string) => {
  switch (type) {
    case "전화":
      return <CallIcon />;
    case "이메일":
      return <MailIcon />;
    case "DM":
      return <SendIcon />;
    case "방문":
      return <PersonPinIcon />;
    case "웨비나":
      return <CoPresentIcon />;
    case "데모":
      return <EngineeringIcon />;
    case "납품":
      return <LocalShippingIcon />;
    case "고객 서비스":
      return <SupportAgentIcon />;
    case "기타":
      return <MoreVertIcon />;
    default:
      return null;
  }
};
