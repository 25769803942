import { Box, Chip, Grid, Modal } from "@mui/material";
import {
  AdminGroupItemBoxContentsPadding,
  AdminGroupItemBoxTitle,
} from "../../../../styles/AdminStyle";
import { CustomText } from "../../../../styles/CustomText";
import {
  CustomButtonGreen,
  CustomButtonSmallCommon,
  CustomButtonSmallRed,
} from "../../../../styles/ButtonStyle";
import { GroupInfo } from "../../../../system/types/Group";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import Swal from "sweetalert2";
import { GREENCONTENTS, GREENTITLE, REDTITLE } from "../../../../styles/Color";
import { GroupApi } from "../../../../system/api/GroupApi";
import AlertModal from "../../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../../system/ApiService";
import uuid from "react-uuid";
import { EmployeeInfo } from "../../../../system/types/Employee";
import { useEffect, useState } from "react";
import { InitGroupInfo } from "../../../../system/types/initObject";
import {
  DropDownListComponentBox,
  DropDownListComponentGridBox,
  InputBox,
  ModalStyle,
  PointerItem,
} from "../../../../styles/theme";
import { CheckCircle, X } from "@phosphor-icons/react";
interface AdminGroupSettingProps {
  groupData: GroupInfo;
  refreshData: () => void;
  employeeList: EmployeeInfo[];
}

function validation(data: GroupInfo) {
  if (data.name === "") {
    AlertModal("check", "이름을 작성해 주세요.");
    return false;
  }
  return true;
}

function AdminGroupSetting({
  groupData,
  refreshData,
  employeeList,
}: AdminGroupSettingProps) {
  const [subGroupData, setSubGroupData] = useState<GroupInfo>({
    ...InitGroupInfo,
  });

  useEffect(() => {
    setSubGroupData((prev) => ({
      ...prev,
      level: groupData.level + 1,
      parentId: groupData.id ? groupData.id : null,
    }));
  }, [groupData.id, groupData.level]);

  const deleteGroup = (id: number | null) => {
    Swal.fire({
      title: "정말 삭제하시겠습니까?",
      text: "선택하신 그룹이 삭제됩니다.",
      icon: "question",
      confirmButtonText: "예",
      cancelButtonText: "아니오",
      showCancelButton: true,
      confirmButtonColor: GREENCONTENTS,
      cancelButtonColor: REDTITLE,
    }).then((result) => {
      if (result.isConfirmed && id) {
        GroupApi.deleteGroupInfo(id)
          .then(() => {
            AlertModal("success", "선택하신 그룹이 삭제되었습니다.");
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    });
  };
  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setSubGroupData({
      ...InitGroupInfo,
      level: groupData.level + 1,
      parentId: groupData.id ? groupData.id : null,
    });
  };
  const openCreateModal = () => {
    setOpen(true);
  };

  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setSubGroupData({
        ...subGroupData,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else {
      setSubGroupData({
        ...subGroupData,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    }
  };

  const positionFields = { text: "name", value: "id" };

  var submitcheck = false;
  const onSave = () => {
    if (validation(groupData)) {
      if (submitcheck === false) {
        submitcheck = true;
        GroupApi.createGroupInfo(subGroupData)
          .then(() => {
            refreshData();
            handleClose();
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  };

  const itemTemplate = (data: EmployeeInfo) => {
    return (
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={4}>
          {data.name}
        </Grid>
        <Grid item container xs={8} justifyContent="right">
          {data.jobTitle?.name}{" "}
          {data.jobTitle?.name && data.jobPosition?.name && "/"}{" "}
          {data.jobPosition?.name}
        </Grid>
      </Grid>
    );
  };

  const valueTemplate = (data: EmployeeInfo) => {
    return (
      <CustomText type="subtitle" bold400 color="#000000">
        {data.name}
      </CustomText>
    );
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };

  return (
    <>
      <AdminGroupItemBoxTitle
        paddingnumber="6px 10px"
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item xs={6}>
          <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
            하위 그룹
          </CustomText>
          <Chip
            icon={<PeopleOutlineIcon />}
            size="small"
            label={groupData.children.length}
            variant="outlined"
          />
        </Grid>
        <Grid container item justifyContent="right" xs={6}>
          <CustomButtonSmallCommon onClick={openCreateModal}>
            추가
          </CustomButtonSmallCommon>
        </Grid>
      </AdminGroupItemBoxTitle>
      {groupData.children.length > 0 ? (
        <>
          {groupData.children.map((group: GroupInfo) => {
            return (
              <Grid item xs={12} key={uuid()}>
                <AdminGroupItemBoxContentsPadding
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CustomText type="subtitle" bold500 color="#3D4861">
                    {group.name}
                  </CustomText>
                  <Grid>
                    <CustomButtonSmallRed
                      onClick={() => deleteGroup(group.id ? group.id : null)}
                    >
                      삭제
                    </CustomButtonSmallRed>
                  </Grid>
                </AdminGroupItemBoxContentsPadding>
              </Grid>
            );
          })}
        </>
      ) : (
        <>
          <Grid container justifyContent="center" margin="10px">
            <CustomText type="subtitle" textAlign="center" color="#3D4861">
              하위 그룹을 추가해주세요.
            </CustomText>
          </Grid>
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title">하위 그룹 생성</CustomText>

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">이름</Grid>
                {subGroupData.name === "" ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 이름을 입력해 주세요.
                  </CustomText>
                ) : (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                )}
              </Grid>
              <InputBox
                type="text"
                name="name"
                onChange={(e: any) => {
                  onChange(e, "input");
                }}
                value={subGroupData.name || ""}
                placeholder="이름"
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">그룹 관리자</Grid>
                {subGroupData.manager?.name === "" ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 그룹 관리자를 선택해주세요.
                  </CustomText>
                ) : (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                )}
              </Grid>
              <DropDownListComponentGridBox>
                <DropDownListComponentBox
                  dataSource={employeeList}
                  fields={positionFields}
                  name="manager"
                  onChange={(e: any) => {
                    onChange(e, "dropdown");
                  }}
                  value={subGroupData.manager?.id}
                  placeholder="그룹 관리자를 선택해주세요."
                  itemTemplate={itemTemplate}
                  valueTemplate={valueTemplate}
                  autoComplete="new-password"
                />
              </DropDownListComponentGridBox>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="right" mt="20px">
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default AdminGroupSetting;
