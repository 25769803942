import { useHistory, useParams } from "react-router-dom";
import { CustomText } from "../../styles/CustomText";
import { NoticeInfo } from "../../system/types/Board";
import { useCallback, useEffect, useRef, useState } from "react";
import { NoticeApi } from "../../system/api/NoticeApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../../components/Common/AlertModal";
import { InitNotcieInfo } from "../../system/types/initObject";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
} from "@mui/material";

import { GRAYPALEBORDER } from "../../styles/Color";
import { CustomButtonGreen } from "../../styles/ButtonStyle";
import { useRecoilValue } from "recoil";
import { AdminState } from "../../system/atoms";
import { AttachmentFilesInfo } from "../../system/types/MeetingNote";
import { AttachmentFileDefaultBox } from "../../styles/BycaseStyle";
import CloseIcon from "@mui/icons-material/Close";
import { red } from "@mui/material/colors";
import { BlobServiceClient } from "@azure/storage-blob";
import { TokenApi } from "../../system/api/TokenApi";
import uuid from "react-uuid";
import { CustomFileUpload, DisabledImgBox } from "../../styles/theme";
import Editor from "../../components/Common/Editor/Editor";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import * as gregorian from "../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as numberingSystems from "../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import { isoDateFormatterAll } from "../../components/Common/IsoDateFormatterAll";
interface Params {
  id: string;
}

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({
  ko: {
    datepicker: { today: "오늘" },
  },
});

function validation(data: NoticeInfo, mainNoticeState: boolean) {
  if (data.title === "") {
    AlertModal("check", "제목을 작성해 주세요.");
    return false;
  } else if (mainNoticeState && (!data.mainStartDate || !data.mainEndDate)) {
    AlertModal("check", "주요 공지 설정 기간을 설정해 주세요.");
    return false;
  }
  return true;
}

function NoticeEditPage() {
  const { id } = useParams<Params>();
  const detailId = parseInt(id);
  const [data, setData] = useState<NoticeInfo>(InitNotcieInfo);
  const history = useHistory();
  const adminValue = useRecoilValue(AdminState);
  const refContent = useRef<Editor>(null);
  const getNotice = useCallback(() => {
    if (detailId) {
      NoticeApi.getNoticeDetail(detailId)
        .then((res) => {
          if (adminValue) {
            setData(res.data);
          } else {
            history.push(`/notice`);
            AlertModal("msg", "해당 공지사항의 수정 권한이 없습니다.");
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [detailId, adminValue, history]);

  useEffect(() => {
    getNotice();
  }, [getNotice]);

  var submitcheck = false;
  function onSave() {
    if (validation(data, mainNoticeState)) {
      submitcheck = true;

      if (submitcheck === true) {
        UploadFileToBlob(fileList);
        if (data.id) {
          NoticeApi.updateNotice({
            ...data,
            content: refContent.current?.getContent() || "",
          })
            .then(() => {
              AlertModal("success", "공지사항이 수정되었습니다.");
              history.push(`/notice/detail/${data.id}`);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        } else {
          NoticeApi.createNotice({
            ...data,
            content: refContent.current?.getContent() || "",
          })
            .then(() => {
              AlertModal("success", "공지사항이 생성되었습니다.");
              history.push("/notice/");
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        }
      }
    }
  }

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };
  //첨부파일
  const [sasToken, setSasToken] = useState("");
  useEffect(() => {
    if (sasToken === "") {
      TokenApi.getSasToken()
        .then((res) => {
          setSasToken(res.data.sasUrl);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, []);
  const [fileList, setFileList] = useState<File[]>([]);

  const deleteFile = (name: string) => {
    setFileList(fileList.filter((dt: File) => dt.name !== name));
    setData({
      ...data,
      attachmentFiles: data.attachmentFiles.filter(
        (dt: AttachmentFilesInfo) => dt.changedName !== name
      ),
    });
  };
  const AttachmentKeyId = uuid();
  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      let preFileList: File[] = [];
      let preDataFileList: AttachmentFilesInfo[] = [];
      let hasDuplicate = false;
      const attachmentId = AttachmentKeyId;
      for (var i = 0; i < event.target.files.length; i++) {
        const oldFile = event.target.files[i];

        let newFile = null;
        let newDataFile = null;

        newFile = new File(
          [oldFile],
          `${attachmentId + "_" + event.target.files[i].name}`,
          {
            type: oldFile.type,
          }
        );

        newDataFile = {
          filename: event.target.files[i].name,
          changedName: `${attachmentId + "_" + event.target.files[i].name}`,
          filePath:
            "https://inbodyinfra.blob.core.windows.net/inbodylead/" +
            process.env.REACT_APP_ACTIVE_MODE +
            "/" +
            attachmentId +
            "_" +
            event.target.files[i].name,
        };

        if (newFile !== null && newDataFile !== null) {
          const fileName = newFile.name;
          if (fileList.some((file) => file.name === fileName)) {
            hasDuplicate = true;
            AlertModal("msg", `동일한 이름의 파일이 존재합니다: ${fileName}`);
            break;
          } else {
            preFileList = preFileList.concat([newFile]);
            preDataFileList = preDataFileList.concat([newDataFile]);
          }
        }
      }
      if (!hasDuplicate) {
        const completedFileList = fileList.concat(preFileList);
        const completedFileDataList =
          data.attachmentFiles.concat(preDataFileList);
        setFileList(completedFileList);
        setData({ ...data, attachmentFiles: completedFileDataList });
      }
    }
  };
  const UploadFileToBlob = async (file: File[]) => {
    // upload file
    const blobService = new BlobServiceClient(sasToken);
    const containerClient = blobService.getContainerClient("");

    const uploadPromises = file.map((document) => {
      if (document.name === null) {
        return Promise.resolve();
      }

      const blockBlobClient = containerClient.getBlockBlobClient(
        process.env.REACT_APP_ACTIVE_MODE + "/" + document.name
      );

      return blockBlobClient.uploadData(document!);
    });
    return Promise.all(uploadPromises);
  };
  const [mainNoticeState, setMainNoticeState] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (mainNoticeState) {
      setData({
        ...data,
        mainStartDate: null,
        mainEndDate: null,
      });
    }
    setMainNoticeState(event.target.checked);
  };

  const onChangeDate = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: isoDateFormatterAll(
        new Date(args.target.value)
      ),
    });
  };

  return (
    <>
      <CustomText type="title">공지사항 {data.id ? "수정" : "생성"}</CustomText>
      <TableMainBox container mt="20px" justifyContent="center">
        <TimeBox item xs={9}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={mainNoticeState}
                  onChange={handleChange}
                />
              }
              label={
                <CustomText type="subtitle" bold400 marginTop="-5px">
                  주요 공지 설정
                </CustomText>
              }
            />
          </FormGroup>
          {mainNoticeState && (
            <TableContentBox item xs={12} container alignItems="center">
              <Grid item xs={2}>
                주요 공지 시작
              </Grid>
              <Grid item xs={3.8}>
                <DateTimePickerComponent
                  placeholder="시작 날짜 / 시간"
                  format="yyyy-MM-dd aa hh:mm"
                  name="mainStartDate"
                  onChange={onChangeDate}
                  locale="ko"
                  value={
                    data.mainStartDate
                      ? new Date(data.mainStartDate)
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={0.4}></Grid>
              <Grid item xs={2}>
                주요 공지 종료
              </Grid>
              <Grid item xs={3.8}>
                <DateTimePickerComponent
                  placeholder="종료 날짜 / 시간"
                  format="yyyy-MM-dd aa hh:mm"
                  name="mainEndDate"
                  onChange={onChangeDate}
                  locale="ko"
                  value={
                    data.mainEndDate ? new Date(data.mainEndDate) : undefined
                  }
                />
              </Grid>
            </TableContentBox>
          )}
        </TimeBox>
        <TableContentBox item xs={9} container alignItems="center">
          <InputBox
            name="title"
            onChange={onChange}
            value={data.title}
            autoComplete="new-password"
            placeholder="제목을 입력해 주세요."
          />
        </TableContentBox>
        <Grid item xs={9} mt="10px">
          <Editor content={data.content || ""} ref={refContent}></Editor>
        </Grid>
      </TableMainBox>
      <AttachmentTableContentBox container>
        <AttachmentTitleBox
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>첨부파일</Grid>
          <Grid>
            <CustomFileUpload htmlFor={"file"}>
              <Grid container justifyContent="center">
                파일 업로드
              </Grid>
            </CustomFileUpload>
            <DisabledImgBox
              id={"file"}
              type="file"
              onChange={(e) => onSaveFile(e)}
              multiple
            ></DisabledImgBox>
          </Grid>
        </AttachmentTitleBox>
        <Grid container justifyContent="right">
          {data.attachmentFiles.length === 0 ? (
            <Grid container item xs={12} justifyContent="center" mt="10px">
              첨부 파일 없음
            </Grid>
          ) : (
            <>
              {data.attachmentFiles.map(
                (dt: AttachmentFilesInfo, index: number) => (
                  <AttachmentFileDefaultBox
                    key={index}
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid ml="5px"> {dt.filename}</Grid>
                    <CloseIcon
                      onClick={() => deleteFile(dt.changedName)}
                      sx={{ color: red[700], cursor: "pointer" }}
                    />
                  </AttachmentFileDefaultBox>
                )
              )}
            </>
          )}
        </Grid>
      </AttachmentTableContentBox>
      <Grid container justifyContent="right" mt="10px">
        <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
      </Grid>
    </>
  );
}

export default NoticeEditPage;

const TableMainBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  padding: "20px 0px 30px 0px",
});

const TableContentBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  margin: "10px 0px",
  overflow: "auto",
  minHeight: "40px",
  padding: "0px 15px",
});

const TimeBox = styled(Grid)({});

const InputBox = styled("input")({
  width: "100%",
  height: "20px",
  border: "none",
  "&::placeholder": {
    color: "#B2B4B8",
    fontWeight: "300",
    fontSize: "16px",
  },
  "&:hover": { border: "none" },
  "&:focus": { border: "none", outline: "none" },
  "&:active": { border: "none" },
});

const AttachmentTitleBox = styled(Grid)({
  paddingBottom: "5px",
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
});

const AttachmentTableContentBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  padding: "10px 20px",
  marginTop: "10px",
  textAlign: "center",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
});
