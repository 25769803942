import client from "../ApiService";
import HttpClient from "../http-client";
import { AddressInfo } from "../types/Customer";

const baseUri: string = "/addresses";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _AddressApi extends _BasicApi {
  updateAddresses(addressId: number, addressDt: AddressInfo) {
    return this.client.put(`${baseUri}/${addressId}`, addressDt);
  }

  getAddressDistrictInfo(searchType: string, siDo?: string, gunGu?: string) {
    return this.client.get(`${baseUri}/district/list`, {
      params: { searchType: searchType, siDo: siDo, gunGu: gunGu },
    });
  }
  getAddresssListInfo(siDo?: string, gunGu?: string) {
    return this.client.get(`${baseUri}/list`, {
      params: { siDo: siDo, gunGu: gunGu },
    });
  }
}

export const AddressApi = new _AddressApi(client);
