import client from "../ApiService";
import HttpClient from "../http-client";
import { ScheduleInfo } from "../types/Board";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _ScheduleApi extends _BasicApi {
  getSchedule(year: number, month: string) {
    return this.client.get(`/?year=${year}&month=${month}`);
  }
  createSchedule(scheduleData: ScheduleInfo) {
    return this.client.post(`/`, scheduleData);
  }

  updateSchedule(scheduleId: number, scheduleData: ScheduleInfo) {
    return this.client.put(`/${scheduleId}`, scheduleData);
  }

  deleteSchedule(scheduleId: number) {
    return this.client.delete(`/${scheduleId}`);
  }
}

export const ScheduleApi = new _ScheduleApi(client);
