import { Dispatch, useEffect, useMemo, useState } from "react";
import {
  AddressInfo,
  JusoApiInfo,
  LeadCustomerCreateInfo,
} from "../../../../../system/types/Customer";
import * as gregorian from "../../../../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as numberingSystems from "../../../../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import {
  Autocomplete,
  Checkbox,
  debounce,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  EditDropDownListComponentBox,
  EditInputSmallBox,
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  TableFixedHeight,
} from "../../../../../styles/theme";
import { CustomText } from "../../../../../styles/CustomText";
import {
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  REDTITLE,
} from "../../../../../styles/Color";
import { ExpectedTimingConversion } from "../../../../../components/Common/ExpectedTimingConversion";
import { isoDateFormatter } from "../../../../../components/Common/IsoDateFormatter";
import { useRecoilValue } from "recoil";
import {
  AdminState,
  CSState,
  MyInfoState,
  registerLeadStateAtom,
} from "../../../../../system/atoms";
import uuid from "react-uuid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import { InitAddressInfo } from "../../../../../system/types/initObject";
import { DropdownListInfo } from "../../../../../system/types/List";
import { ErrorHandler } from "../../../../../system/ApiService";
import { ListApi } from "../../../../../system/api/ListApi";
import AlertModal from "../../../../../components/Common/AlertModal";
import { FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
interface EditLeadInfoProps {
  leadId: number;
  data: LeadCustomerCreateInfo;
  setData: Dispatch<React.SetStateAction<LeadCustomerCreateInfo>>;
  onSave: () => void;
  directWritePlaceName: string;
  setDirectWritePlaceName: (directWritePlaceName: string) => void;
  directInput: boolean;
  setDirectInput: (directInput: boolean) => void;
}
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({
  ko: {
    datepicker: { today: "오늘" },
    dropdowns: { noRecordsTemplate: "해당 데이터가 없습니다." },
  },
});

function EditLeadInfo({
  data,
  setData,
  onSave,
  directWritePlaceName,
  setDirectWritePlaceName,
  directInput,
  setDirectInput,
}: EditLeadInfoProps) {
  // kakao data
  const [options, setOptions] = useState<AddressInfo[]>([]);
  const [value, setValue]: any = useState(null);
  const [inputValue, setInputValue] = useState("");
  const kakaoKey = "b4cfe488cd8ef4b5f7bc9fbcd0f4b055";
  const jusoKey = `${process.env.REACT_APP_ADDRESS_KEY}`;
  const debounceTic = 100;
  const debounceTic2 = 100;
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const RegisterLeadState = useRecoilValue(registerLeadStateAtom);
  const [jusoValue, setJusoValue]: any = useState(null);
  const [jusoInputValue, setJusoInputValue] = useState("");
  const [jusoOptions, setJusoOptions] = useState<AddressInfo[]>([]);
  const myInfoData = useRecoilValue(MyInfoState);
  const adminValue = useRecoilValue(AdminState);
  const CSValue = useRecoilValue(CSState);
  const [addressData, setAddressData] = useState<AddressInfo>({
    placeName: "",
    road: "",
    bdNm: "",
  });
  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdown") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    } else if (type === "dropdownStage") {
      if (args.target.itemData) {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: args.target.itemData,
          estimatedDate: ExpectedTimingConversion(args.target.itemData.name),
        });
      } else {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: null,
          estimatedDate: null,
        });
      }
    } else if (type === "date") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: isoDateFormatter(
          new Date(args.target.value)
        ),
      });
    } else if (type === "inputdetail") {
      setData({
        ...data,
        address: { ...data.address, detail: args.target.value },
      });
    } else if (type === "inputName") {
      setDirectWritePlaceName(args.target.value);
    } else if (type === "intermediaryDropdown") {
      const targetData = args.target.itemData;
      if (targetData) {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: {
            ...targetData,
            address: args.target.itemData.address,
          },
        });
      } else {
        setData((prev: LeadCustomerCreateInfo) => ({ ...prev }));
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: null,
        });
      }
    }
  };
  const fetchKakaoLocations = useMemo(
    () =>
      debounce(async (value, active) => {
        if (!active || value === null || value === "") {
          setOptions([]);
          return undefined;
        }
        try {
          const response = await axios.get(
            `https://dapi.kakao.com/v2/local/search/keyword.json?query=${value}`,
            {
              headers: {
                Authorization: `KakaoAK ${kakaoKey}`, // YOUR_KAKAO_API_KEY를 발급받은 키로 대체해주세요.
              },
            }
          );

          const locations = response.data.documents.map((location: any) => ({
            road: !location.road_address_name
              ? location.address_name
              : location.road_address_name,
            old: location.address_name,
            locId: location.id,
            category: location.category_name,
            placeName: location.place_name,
            longitude: location.x,
            latitude: location.y,
            placeUrl: location.place_url,
          }));
          setOptions([]);
          setOptions(locations);
        } catch (error) {
          console.error("Error fetching Kakao location data:", error);
        }
      }, debounceTic),
    [debounceTic]
  );
  // 국가주소시스템 API
  const fetchJuso = useMemo(
    () =>
      debounce(async (jusoValue, active) => {
        if (!active || jusoValue === null || jusoValue === "") {
          setJusoOptions([]);
          return undefined;
        }

        try {
          const response = await axios.get(
            `https://business.juso.go.kr/addrlink/addrLinkApi.do?currentPage=1&countPerPage=100&keyword=${jusoValue}&confmKey=${jusoKey}&resultType=json`
          );
          if (directInput === true) {
            const locations = response.data.results?.juso
              ? response.data.results?.juso.map((location: any) => ({
                  admCd: location?.admCd,
                  rn: location?.rn,
                  emdNm: location?.emdNm,
                  zipNo: location?.zipNo,
                  emdNo: location?.emdNo,
                  sggNm: location?.sggNm,
                  engRoad: location?.engAddr,
                  rnMgtSn: location?.rnMgtSn,
                  jibun: location?.jibunAddr,
                  siNm: location?.siNm,
                  bdNm: location?.bdNm,
                  lnbrMnnm: location?.lnbrMnnm,
                  road: location?.roadAddr,
                  lnbrSlno: location?.lnbrSlno,
                  buldMnnm: location?.buldMnnm,
                  liNm: location?.liNm,
                  bdMgtSn: location?.bdMgtSn,
                  buldSlno: location?.buldSlno,
                  category: location?.category,
                }))
              : [];

            setJusoOptions([]);
            setJusoOptions(locations);
          } else {
            const location: JusoApiInfo[] = response.data?.results?.juso;
            const locationData: JusoApiInfo =
              location.length > 0 ? location[0] : {};

            if (locationData) {
              const resultData = {
                placeName: locationData?.bdNm
                  ? locationData?.bdNm
                  : value?.placeName,
                admCd: locationData?.admCd,
                rn: locationData?.rn,
                emdNm: locationData?.emdNm,
                zipNo: locationData?.zipNo,
                emdNo: locationData?.emdNo,
                sggNm: locationData?.sggNm,
                engRoad: locationData?.engAddr,
                rnMgtSn: locationData?.rnMgtSn,
                jibun: locationData?.jibunAddr,
                siNm: locationData?.siNm,
                bdNm: locationData?.bdNm ? locationData?.bdNm : "",
                lnbrMnnm: locationData?.lnbrMnnm,
                road: locationData?.roadAddr ? locationData?.roadAddr : "",
                lnbrSlno: locationData?.lnbrSlno,
                buldMnnm: locationData?.buldMnnm,
                liNm: locationData?.liNm,
                bdMgtSn: locationData?.bdMgtSn,
                buldSlno: locationData?.buldSlno,
              };
              setAddressData({ placeName: "", road: "", bdNm: "" });
              setAddressData(resultData);
            }
          }
        } catch (error) {
          console.error("Error fetching juso location data:", error);
        }
      }, debounceTic2),
    [directInput, value?.placeName, jusoKey]
  );

  const onChangeAddress = (args: any, newValue: any) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    setData({ ...data, address: newValue ? newValue : InitAddressInfo });
    const searchAddress = newValue?.road;

    let active = true;
    if (inputValue === "") {
      setJusoOptions(jusoValue ? [jusoValue] : []);
      return undefined;
    }
    fetchJuso(searchAddress, active);

    return () => {
      active = false;
    };
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDirectInput(event.target.checked);
    setData({ ...data, address: InitAddressInfo });
    setValue(null);
    setJusoValue(null);
    setJusoOptions([]);
    setOptions([]);
  };

  const onChangePhone = (args: any) => {
    const targetValue = args.target.value
      // eslint-disable-next-line
      .replace(/[^0-9]/g, "")
      // eslint-disable-next-line
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      // eslint-disable-next-line
      .replace(/(\-{1,2})$/g, "");
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: targetValue,
    });
  };
  // 업종

  const [IndustryClassificationData, setIndustryClassificationData] = useState<
    DropdownListInfo[]
  >([]);
  const industryClassficationFields: Object = {
    dataSource: IndustryClassificationData,
    value: "id",
    text: "name",
    child: "children",
  };
  useEffect(() => {
    ListApi.getDropdownList("업종구분")
      .then((res) => {
        setIndustryClassificationData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 과/팀/센터
  const [classTeamCenterData, setClassTeamCenterData] = useState<
    DropdownListInfo[]
  >([]);
  const classTeamCenterDataFields: Object = {
    dataSource: classTeamCenterData,
    value: "id",
    text: "name",
    child: "children",
  };
  useEffect(() => {
    ListApi.getDropdownList("과팀센터")
      .then((res) => {
        setClassTeamCenterData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onFiltering = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("name", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  // 영업 단계
  const [saleStageData, setSaleStageData] = useState<DropdownListInfo[]>([]);
  const saleStageDataFields: Object = {
    value: "id",
    text: "name",
  };
  useEffect(() => {
    ListApi.getDropdownList("영업단계")
      .then((res) => {
        setSaleStageData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  useEffect(() => {
    if (directInput === true) {
      let active = true;
      if (jusoInputValue === "") {
        setJusoOptions(jusoValue ? [jusoValue] : []);
        return undefined;
      }

      setTimeoutId((prevTimeoutId) => {
        // Cancel the previous timeout
        if (prevTimeoutId) {
          clearTimeout(prevTimeoutId);
        }

        // Set up a new timeout
        const newTimeoutId = setTimeout(() => {
          fetchJuso(jusoInputValue, active);
        }, 300);

        return newTimeoutId;
      });

      return () => {
        active = false;
      };
    }
  }, [jusoValue, jusoInputValue, fetchJuso, directInput]);

  useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    setTimeoutId((prevTimeoutId) => {
      // Cancel the previous timeout
      if (prevTimeoutId) {
        clearTimeout(prevTimeoutId);
      }

      // Set up a new timeout
      const newTimeoutId = setTimeout(() => {
        fetchKakaoLocations(inputValue, active);
      }, 300);

      return newTimeoutId;
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetchKakaoLocations]);
  return (
    <LeadTableCardBox style={{ marginTop: "20px" }}>
      <Grid container item xs={12} padding="10px">
        <CustomText type="subtitle">고객 정보</CustomText>
      </Grid>
      <LeadTableContentsBox container item xs={12}>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              상호명
            </CustomText>
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8}>
            <Grid item xs={8}>
              {directInput ? (
                <EditInputSmallBox
                  type="text"
                  name="email"
                  onChange={(e: any) => {
                    onChange(e, "inputName");
                  }}
                  value={directWritePlaceName || ""}
                  placeholder="상호명을 입력해 주세요."
                  style={{ marginTop: "11px", paddingLeft: "15px" }}
                  autoComplete="new-password"
                />
              ) : (
                <Autocomplete
                  sx={{
                    "& .MuiInputBase-root": {
                      fontFamily: "Noto sans CJK KR",

                      paddingLeft: "3px",
                      "&.MuiInput-root:after": {
                        borderBottom: "2px solid #000000",
                      },
                    },
                  }}
                  getOptionLabel={(option: AddressInfo) =>
                    typeof option === "string"
                      ? value?.placeName
                      : option.placeName
                  }
                  size="small"
                  filterOptions={(x) => x}
                  options={options}
                  autoComplete
                  //   includeInputInList
                  autoSelect
                  filterSelectedOptions
                  value={value ? value : null}
                  isOptionEqualToValue={(
                    option: AddressInfo,
                    value: AddressInfo
                  ) => option.id === value.id}
                  onChange={(event, newValue) => {
                    onChangeAddress(event, newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={uuid()}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ display: "flex", width: 44 }}>
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                          </Grid>
                          <Grid
                            item
                            sx={{
                              width: "calc(100% - 44px)",
                              wordWrap: "break-word",
                            }}
                          >
                            {option.placeName}

                            <Typography variant="body2" color="text.secondary">
                              {option.road}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{ width: "90%", margin: "5px 5% 0px 5%" }}
                    />
                  )}
                  noOptionsText={
                    <Grid container justifyContent="center" margin="10px 0px">
                      <CustomText
                        type="subtitle"
                        textAlign="center"
                        color="#3D4861"
                      >
                        검색 결과가 없습니다.
                      </CustomText>
                    </Grid>
                  }
                />
              )}
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={directInput}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <CustomText type="subtitle" bold400 marginTop="-5px">
                      직접 입력
                    </CustomText>
                  }
                />
              </FormGroup>
            </Grid>
          </LeadTableContent>
        </TableFixedHeight>

        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              고객 담당자
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8} padding="0px 10px">
            <EditInputSmallBox
              type="text"
              name="name"
              onChange={(e: any) => {
                onChange(e, "input");
              }}
              value={data.name || ""}
              placeholder="고객 담당자를 작성해 주세요."
              autoComplete="new-password"
            />
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              고객 연락처(핸드폰)
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8} padding="0px 10px">
            <EditInputSmallBox
              type="text"
              name="phone"
              onChange={(e: any) => {
                onChangePhone(e);
              }}
              value={data.phone || ""}
              placeholder="ex) 010-0000-0000"
              autoComplete="new-password"
            />
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              고객 연락처(내선)
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8} padding="0px 10px">
            <EditInputSmallBox
              type="text"
              name="officeNumber"
              onChange={(e: any) => {
                onChange(e, "input");
              }}
              value={data.officeNumber || ""}
              placeholder="ex) 02-000-0000"
              autoComplete="new-password"
            />
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              고객 주소
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8}>
            <Grid item xs={12}>
              {directInput ? (
                <Autocomplete
                  size="small"
                  sx={{
                    "& .MuiInputBase-root": {
                      fontFamily: "Noto sans CJK KR",
                      "&.MuiInput-root:after": {
                        borderBottom: "2px solid #000000",
                      },
                    },
                  }}
                  getOptionLabel={(option: AddressInfo) =>
                    typeof option === "string" ? option : option.road
                  }
                  filterOptions={(x) => x}
                  options={jusoOptions}
                  autoComplete
                  //   includeInputInList
                  autoSelect
                  filterSelectedOptions
                  value={jusoValue ? jusoValue : null}
                  isOptionEqualToValue={(
                    option: AddressInfo,
                    value: AddressInfo
                  ) => option.id === value.id}
                  onChange={(event, newValue) => {
                    setOptions(
                      newValue ? [newValue, ...jusoOptions] : jusoOptions
                    );
                    setJusoValue(newValue);
                    setData({
                      ...data,
                      address: newValue ? newValue : InitAddressInfo,
                    });
                  }}
                  onInputChange={(event, newInputValue) => {
                    setJusoInputValue(newInputValue);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={uuid()}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ display: "flex", width: 44 }}>
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                          </Grid>
                          <Grid
                            item
                            sx={{
                              width: "calc(100% - 44px)",
                              wordWrap: "break-word",
                            }}
                          >
                            {option.placeName}
                            <Typography variant="body2" color="text.secondary">
                              {option.road}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {option.jibun}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{ width: "94%", marginTop: "5px", marginLeft: "3%" }}
                    />
                  )}
                  noOptionsText={
                    <Grid container justifyContent="center" margin="10px 0px">
                      <CustomText
                        type="subtitle"
                        textAlign="center"
                        color="#3D4861"
                      >
                        검색 결과가 없습니다.
                      </CustomText>
                    </Grid>
                  }
                />
              ) : (
                <>
                  {data?.address?.road ? (
                    <Grid marginLeft="25px">
                      <CustomText type="subtitle" bold400>
                        {data?.address?.road}
                      </CustomText>
                    </Grid>
                  ) : (
                    <Grid margin="5px 0px 0px 15px">
                      <CustomText color="#B2B4B8" type="subtitle" bold300>
                        상호명을 입력하시면 자동 입력됩니다.
                      </CustomText>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              상세 주소
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8} padding="0px 10px">
            <EditInputSmallBox
              type="text"
              name="email"
              onChange={(e: any) => {
                onChange(e, "inputdetail");
              }}
              value={data.address.detail || ""}
              placeholder="상세 주소를 작성해 주세요."
              autoComplete="new-password"
            />
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              고객 이메일
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8} padding="0px 10px">
            <EditInputSmallBox
              type="text"
              name="email"
              onChange={(e: any) => {
                onChange(e, "input");
              }}
              value={data.email || ""}
              placeholder="고객 이메일 주소를 작성해 주세요."
              autoComplete="new-password"
            />
          </LeadTableContent>
        </TableFixedHeight>
        {data.customerType === "일반" && (
          <>
            <TableFixedHeight container item xs={12} sm={6} alignItems="center">
              <LeadTableTitle container justifyContent="right" item xs={4}>
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALECONTENTS}
                >
                  영업 단계
                </CustomText>
                <CustomText fontSize="small" color={REDTITLE}>
                  *
                </CustomText>
              </LeadTableTitle>
              <LeadTableContent container item xs={8} padding="0px 10px">
                <EditDropDownListComponentBox
                  locale="ko"
                  showClearButton={true}
                  dataSource={saleStageData}
                  filtering={(args: any) => onFiltering(args, saleStageData)}
                  allowFiltering={true}
                  fields={saleStageDataFields}
                  name="step"
                  value={data.step?.id}
                  onChange={(e: any) => {
                    onChange(e, "dropdownStage");
                  }}
                  placeholder={
                    data.step?.id ? data.step.name : "영업단계를 선택해주세요."
                  }
                  autoComplete="new-password"
                />
              </LeadTableContent>
            </TableFixedHeight>
            <TableFixedHeight container item xs={12} sm={6} alignItems="center">
              <LeadTableTitle container justifyContent="right" item xs={4}>
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALECONTENTS}
                >
                  업종
                </CustomText>
                {!CSValue && (
                  <CustomText fontSize="small" color={REDTITLE}>
                    *
                  </CustomText>
                )}
              </LeadTableTitle>
              <LeadTableContent container item xs={8} padding="0px 10px">
                <EditDropDownListComponentBox
                  locale="ko"
                  showClearButton={true}
                  dataSource={IndustryClassificationData}
                  filtering={(args: any) =>
                    onFiltering(args, IndustryClassificationData)
                  }
                  allowFiltering={true}
                  fields={industryClassficationFields}
                  name="businessType"
                  value={data.businessType?.id}
                  onChange={(e: any) => {
                    onChange(e, "dropdown");
                  }}
                  placeholder={
                    data.businessType?.id
                      ? data.businessType.name
                      : "업종을 선택해주세요."
                  }
                  autoComplete="new-password"
                />
              </LeadTableContent>
            </TableFixedHeight>
            <TableFixedHeight container item xs={12} sm={6} alignItems="center">
              <LeadTableTitle container justifyContent="right" item xs={4}>
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALECONTENTS}
                >
                  과/팀/센터
                </CustomText>
                {(data.businessType?.name === "양방종합병원" ||
                  data.businessType?.name === "한방병원") && (
                  <CustomText fontSize="small" color={REDTITLE}>
                    *
                  </CustomText>
                )}
              </LeadTableTitle>
              <LeadTableContent container item xs={8} padding="0px 10px">
                <EditDropDownListComponentBox
                  locale="ko"
                  dataSource={classTeamCenterData}
                  filtering={(args: any) =>
                    onFiltering(args, classTeamCenterData)
                  }
                  allowFiltering={true}
                  fields={classTeamCenterDataFields}
                  name="department"
                  value={data.department?.id}
                  showClearButton={true}
                  onChange={(e: any) => {
                    onChange(e, "dropdown");
                  }}
                  placeholder={
                    data.department?.id
                      ? data.department.name
                      : "과/팀/센터를 선택해주세요."
                  }
                  autoComplete="new-password"
                />
              </LeadTableContent>
            </TableFixedHeight>
          </>
        )}
      </LeadTableContentsBox>
    </LeadTableCardBox>
  );
}

export default EditLeadInfo;
