import { Box, Grid, Modal } from "@mui/material";
import {
  AdminGroupItemBoxContents,
  AdminGroupItemBoxTitle,
} from "../../../../styles/AdminStyle";
import { CustomText } from "../../../../styles/CustomText";
import { GroupInfo } from "../../../../system/types/Group";
import {
  CustomButtonGreen,
  CustomButtonSmallCommon,
} from "../../../../styles/ButtonStyle";
import {
  DropDownListComponentBox,
  DropDownListComponentGridBox,
  InputBox,
  ModalStyle,
  PointerItem,
} from "../../../../styles/theme";
import { useEffect, useState } from "react";
import { CheckCircle, X } from "@phosphor-icons/react";
import { GREENTITLE, REDTITLE } from "../../../../styles/Color";
import { EmployeeInfo } from "../../../../system/types/Employee";
import AlertModal from "../../../../components/Common/AlertModal";
import { GroupApi } from "../../../../system/api/GroupApi";
import { ErrorHandler } from "../../../../system/ApiService";
import { ENABLED_TYPE } from "../../../../system/Constants";

interface AdminGroupInfoProps {
  groupData: GroupInfo;

  refreshData: () => void;
  employeeList: EmployeeInfo[];
}

function validation(data: GroupInfo) {
  if (data.name === "") {
    AlertModal("check", "이름을 작성해 주세요.");
    return false;
  }
  return true;
}

function AdminGroupInfo({
  groupData,
  refreshData,
  employeeList,
}: AdminGroupInfoProps) {
  const [editGroupData, setEditGroupData] = useState<GroupInfo>({
    ...groupData,
  });

  useEffect(() => {
    setEditGroupData(groupData);
  }, [groupData]);

  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const openEditModal = () => {
    setOpen(true);
  };

  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setEditGroupData({
        ...editGroupData,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else {
      setEditGroupData({
        ...editGroupData,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    }
  };
  const positionFields = { text: "name", value: "id" };

  var submitcheck = false;
  const onSave = () => {
    if (validation(editGroupData)) {
      if (submitcheck === false) {
        submitcheck = true;
        GroupApi.updateGroupInfo(editGroupData)
          .then(() => {
            refreshData();
            handleClose();
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  };

  const itemTemplate = (data: EmployeeInfo) => {
    return (
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={4}>
          {data.name}
        </Grid>
        <Grid item container xs={8} justifyContent="right">
          {data.jobTitle?.name}{" "}
          {data.jobTitle?.name && data.jobPosition?.name && "/"}{" "}
          {data.jobPosition?.name}
        </Grid>
      </Grid>
    );
  };

  const valueTemplate = (data: EmployeeInfo) => {
    return (
      <CustomText type="subtitle" bold400 color="#000000">
        {data.name}
      </CustomText>
    );
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };
  const enabledFields = { text: "name", value: "value" };
  const onChangeEnabled = (args: any) => {
    if (args.target.value === true) {
      setEditGroupData((prev) => ({ ...prev, isSaleGroup: true }));
    } else {
      setEditGroupData((prev) => ({ ...prev, isSaleGroup: false }));
    }
  };

  return (
    <>
      <AdminGroupItemBoxTitle
        paddingnumber="6px 10px"
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item xs={6}>
          <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
            정보
          </CustomText>
        </Grid>
        <Grid container item justifyContent="right" xs={6}>
          <CustomButtonSmallCommon onClick={openEditModal}>
            수정
          </CustomButtonSmallCommon>
        </Grid>
      </AdminGroupItemBoxTitle>
      <Grid item xs={12}>
        {groupData.id ? (
          <>
            <AdminGroupItemBoxContents container alignItems="center">
              <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                이름
              </CustomText>
              <CustomText type="subtitle" bold500 color="#3D4861">
                {groupData.name}
              </CustomText>
            </AdminGroupItemBoxContents>
            <AdminGroupItemBoxContents container alignItems="center">
              <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                그룹 관리자
              </CustomText>
              <CustomText type="subtitle" bold500 color="#3D4861">
                {groupData.manager?.name ? groupData.manager.name : "없음"}
              </CustomText>
            </AdminGroupItemBoxContents>
            <AdminGroupItemBoxContents container alignItems="center">
              <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                영업 그룹 여부
              </CustomText>
              <CustomText type="subtitle" bold500 color="#3D4861">
                {groupData.isSaleGroup ? "활성화" : "비활성화"}
              </CustomText>
            </AdminGroupItemBoxContents>
          </>
        ) : (
          <>
            <Grid container justifyContent="center" margin="10px">
              <CustomText type="subtitle" textAlign="center" color="#3D4861">
                해당 정보가 없습니다.
              </CustomText>
            </Grid>
          </>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <Grid container spacing={2}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                item
                xs={12}
              >
                <CustomText type="title"> 정보 수정</CustomText>

                <PointerItem onClick={handleClose}>
                  <X size={32} />
                </PointerItem>
              </Grid>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <Grid mr="5px">이름</Grid>
                  {editGroupData.name === "" ? (
                    <CustomText type="small" color={REDTITLE}>
                      * 이름을 입력해 주세요.
                    </CustomText>
                  ) : (
                    <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                  )}
                </Grid>
                <InputBox
                  type="text"
                  name="name"
                  onChange={(e: any) => {
                    onChange(e, "input");
                  }}
                  value={editGroupData.name || ""}
                  placeholder="이름"
                  onKeyDown={handleOnKeyDown}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid>그룹 관리자</Grid>
                <DropDownListComponentGridBox>
                  <DropDownListComponentBox
                    fields={positionFields}
                    dataSource={employeeList}
                    name="manager"
                    value={groupData.manager?.id}
                    onChange={(e: any) => {
                      onChange(e, "dropdown");
                    }}
                  />
                </DropDownListComponentGridBox>
              </Grid>
              <Grid item xs={12}>
                <Grid>영업 그룹 여부</Grid>
                <DropDownListComponentGridBox>
                  <DropDownListComponentBox
                    fields={enabledFields}
                    dataSource={ENABLED_TYPE}
                    value={editGroupData.isSaleGroup}
                    onChange={onChangeEnabled}
                    placeholder="그룹 관리자를 선택해주세요."
                    itemTemplate={itemTemplate}
                    valueTemplate={valueTemplate}
                    autoComplete="new-password"
                  />
                </DropDownListComponentGridBox>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="right" mt="20px">
              <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </>
  );
}

export default AdminGroupInfo;
