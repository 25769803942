import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  styled,
  Tooltip,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { CustomText } from "../../../styles/CustomText";
import LeadTableListPc from "./components/LeadTableListPc";
import {
  CustomButtonGreen,
  CustomButtonYellow,
} from "../../../styles/ButtonStyle";
import { Fragment, useEffect, useState } from "react";

import {
  Pc,
  TabletMobile,
} from "../../../components/Common/Responseive/ResponsiveScreen";
import LeadTableListMobile from "./components/LeadTableListMobile";
import { LeadCustomerTableInfo } from "../../../system/types/Customer";
import { CustomerApi } from "../../../system/api/CustomerApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../../components/Common/AlertModal";
import {
  EmptyTableBolder,
  PointerItem,
  TableBorderBox,
  TableTitleBox,
} from "../../../styles/theme";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AdminState,
  BoardPageNumber,
  LoadingAtom,
  SearchDataAtom,
  loginState,
} from "../../../system/atoms";
import uuid from "react-uuid";

import { GroupApi } from "../../../system/api/GroupApi";
import { AxiosResponse } from "axios";
import ListSearchBar from "../../../components/ListSearchBar";
import {
  GroupInfo,
  SalesProtectionStatisticsInfo,
} from "../../../system/types/Group";
import SecurityIcon from "@mui/icons-material/Security";
import { X } from "@phosphor-icons/react";
function LeadPage() {
  const [data, setData] = useState<LeadCustomerTableInfo[]>([]);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [pageNumber, setPageNumber] = useRecoilState(BoardPageNumber);
  const [searchDt, setSearchDt] = useRecoilState(SearchDataAtom);
  const adminValue = useRecoilValue(AdminState);

  const [groupData, setGroupData] = useState<GroupInfo[]>([]);
  const [groupDataState, setGroupDataState] = useState(false);
  const LoginStatus = useRecoilValue(loginState);
  const [, setLoadingSpinnerState] = useRecoilState(LoadingAtom);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  // token 있으면
  var token = "";
  const existedToken = localStorage.getItem("accessToken");

  const refreshData = () => {
    CustomerApi.getCustomerInfo(searchDt, pageNumber - 1, 10, false)
      .then((res) => {
        setTotalPageNumber(res.data.totalPages);
        setData(res.data.customers);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    if (token || existedToken) {
      refreshData();
    }
  }, [
    pageNumber,
    searchDt,
    LoginStatus,
    token,
    existedToken,
    searchDt.salesProtection,
  ]);

  useEffect(() => {
    if (token || existedToken) {
      GroupApi.getAllGroupInfo()
        .then((res) => {
          setGroupData(res.data);
          setGroupDataState(true);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [setGroupData, LoginStatus, token, existedToken]);

  const downloadExcel = () => {
    setLoadingSpinnerState(true);
    CustomerApi.getCustomerInfo(searchDt, 0, 10000, true)
      .then((res) => {
        const blob = new Blob([res.data]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.style.display = "none";
        const injectFilename = (res: AxiosResponse<any, any>) => {
          var filename = "";
          var disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return decodeURI(filename);
        };
        link.download = injectFilename(res);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        AlertModal("success", "엑셀 다운로드가 완료되었습니다.");
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        setLoadingSpinnerState(false);
      });
  };

  // 날짜
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const day = new Date().getDate();

  const todayDate = `${year}${month >= 10 ? month : "0" + month}${
    day >= 10 ? day : "0" + day
  }`;

  function AdminDownload() {
    setLoadingSpinnerState(true);
    CustomerApi.getAdminCustomerFile()
      .then((res) => {
        // 받아온 데이터를 Blob으로 변환
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        // Blob을 파일로 다운로드
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `관리자용_PipeLine_정보_${todayDate}.xlsx`; // 다운로드될 파일 이름 설정
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        setLoadingSpinnerState(false);
      });
  }
  const onChangeProtection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDt({
      ...searchDt,
      salesProtection: event.target.checked === true ? true : null,
    });
  };

  const [salesProtectionData, setSalesProtectionData] = useState<
    SalesProtectionStatisticsInfo[]
  >([]);

  const refreshProtection = () => {
    GroupApi.getSalesProtectionStatistics()
      .then((res) => {
        setSalesProtectionData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    refreshProtection();
  }, []);

  const totalProtectionCount = salesProtectionData.reduce(
    (accumulator, current) => {
      return accumulator + current.totalProtectionCount;
    },
    0
  );

  const protectionCount = salesProtectionData.reduce((accumulator, current) => {
    return accumulator + current.protectionCount;
  }, 0);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomText type="title"> Pipeline 목록</CustomText>
      {groupDataState && (
        <ListSearchBar
          searchType="lead"
          setPageNumber={setPageNumber}
          groupData={groupData}
        />
      )}
      <Grid container justifyContent="space-between" item xs={12}>
        <Grid container item xs={12} sm={6} alignItems="center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={searchDt.salesProtection === true ? true : false}
                  onChange={onChangeProtection}
                />
              }
              label={
                <CustomText type="subtitle" bold400 marginTop="-5px">
                  영업 보호권 적용만 보기
                </CustomText>
              }
            />
          </FormGroup>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
          justifyContent="right"
        >
          <Tooltip
            arrow
            title={
              <Fragment>
                <CustomText type="body">
                  [지사명 : 사용 개수 / 지사 내 영업 보호권 개수]
                </CustomText>
                {salesProtectionData.map(
                  (dt: SalesProtectionStatisticsInfo) => (
                    <CustomText type="body" key={uuid()}>
                      {dt.groupName +
                        " : " +
                        dt.protectionCount +
                        "/" +
                        dt.totalProtectionCount}
                    </CustomText>
                  )
                )}
              </Fragment>
            }
          >
            <Chip
              icon={<SecurityIcon />}
              label={protectionCount + "/" + totalProtectionCount}
              variant="outlined"
              onClick={() => setOpen(true)}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        </Grid>
      </Grid>

      {adminValue && (
        <Grid container item justifyContent="right" xs={12}>
          <Grid item mr="5px">
            <CustomButtonYellow onClick={AdminDownload}>
              관리자용 파일
            </CustomButtonYellow>
          </Grid>
          <Grid item>
            <CustomButtonGreen onClick={downloadExcel}>
              엑셀 다운로드
            </CustomButtonGreen>
          </Grid>
        </Grid>
      )}

      <Pc>
        <TableBorderBox container mt="10px">
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={0.5}
          >
            영업 <br />
            보호권
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={1}
          >
            지사
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={1}
          >
            상호
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={1.5}
          >
            주소
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={0.5}
          >
            제품군
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={1}
          >
            모델명
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={1}
          >
            업종
          </TableTitleBox>
          <TableTitleBox
            item
            container
            alignItems="center"
            justifyContent="center"
            xs={0.7}
          >
            판매방식
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={1}
          >
            영업방식
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={0.8}
          >
            영업 담당자
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={1}
          >
            구매예상시기
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={1}
          >
            미팅노트 작성일
          </TableTitleBox>
          <TableTitleBox
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={1}
          >
            문서 작성일
          </TableTitleBox>
          {data &&
            data.map((dt: LeadCustomerTableInfo, index: number) => (
              <Grid container key={uuid()}>
                <Grid container key={index}>
                  <LeadTableListPc
                    data={dt}
                    refreshData={refreshData}
                    refreshProtection={refreshProtection}
                  />
                </Grid>
              </Grid>
            ))}
          {data.length === 0 && (
            <EmptyTableBolder
              container
              justifyContent="center"
              alignItems="center"
            >
              조회된 데이터가 없습니다.
            </EmptyTableBolder>
          )}
        </TableBorderBox>
      </Pc>
      <TabletMobile>
        <LeadTableListMobile data={data} type="lead" />
      </TabletMobile>
      <Grid container justifyContent="center" alignItems="center" mt="20px">
        <Pagination
          count={totalPageNumber}
          showFirstButton
          showLastButton
          page={pageNumber}
          boundaryCount={2}
          onChange={handleChange}
          size="large"
        />
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title"> 영업 보호권 현황</CustomText>

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid item xs={12}>
              <CustomText type="subtitle">
                [지사명 : 사용 개수 / 지사 내 영업 보호권 개수]
              </CustomText>
              {salesProtectionData.map((dt: SalesProtectionStatisticsInfo) => (
                <CustomText
                  type="subtitle"
                  bold400
                  key={uuid()}
                  marginTop="10px"
                >
                  {dt.groupName +
                    " : " +
                    dt.protectionCount +
                    "/" +
                    dt.totalProtectionCount}
                </CustomText>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default LeadPage;

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "30rem",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  borderRadius: "5px",
};
