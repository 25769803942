import client from "../ApiService";
import HttpClient from "../http-client";
import {
  SalesActivityCreateInfo,
  SalesActivityInfo,
} from "../types/SalesActivity";

const listUri: string = "/sales-activities";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _SalesActivityApi extends _BasicApi {
  createSalesActivity(data: SalesActivityCreateInfo) {
    return this.client.post(`${listUri}`, data);
  }
  getSalesActivity(customerId: number) {
    return this.client.get(`${listUri}/${customerId}`);
  }
  updateSalesActivity(salesActivityId: number, data: SalesActivityInfo) {
    return this.client.put(`${listUri}/${salesActivityId}`, data);
  }

  deleteSalesActivity(salesActivityId: number) {
    return this.client.delete(`${listUri}/${salesActivityId}`);
  }
  getNewSalesActivity() {
    return this.client.get(`${listUri}/new`);
  }
}

export const SalesActivityApi = new _SalesActivityApi(client);
