import { Grid, IconButton, styled, Tooltip } from "@mui/material";
import {
  DropDownListComponentBox,
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  LeadTableTitleHover,
  TableFixedHeight,
  TextareaLineBox,
} from "../../../../../styles/theme";
import {
  AddressInfo,
  IntermediaryCompanyInfo,
  LeadCustomerInfo,
  LeadTagInfo,
  SectionStatesInfo,
} from "../../../../../system/types/Customer";
import { CustomText } from "../../../../../styles/CustomText";
import {
  GRAYPALEBORDER,
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  GREENTITLE,
  REDTITLE,
} from "../../../../../styles/Color";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ContactTypeListInfo,
  DropdownListInfo,
  TagListInfo,
} from "../../../../../system/types/List";
import { FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import SelectTag from "../../../../../components/Common/SelectTag";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { PURCHASEPROBABILITY_TYPE } from "../../../../../system/Constants";
import { isoDateFormatter } from "../../../../../components/Common/IsoDateFormatter";
import AlertModal from "../../../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../../../system/ApiService";
import { CustomerApi } from "../../../../../system/api/CustomerApi";
interface LeadEtcProps {
  data: LeadCustomerInfo | null;
  refreshData: () => void;
  toggleSection: (title: keyof SectionStatesInfo) => void;
  sectionStates: SectionStatesInfo;
  intermediaryData: IntermediaryCompanyInfo[];
  saleTypeData: DropdownListInfo[];
  contactTypeData: ContactTypeListInfo[];
  contractTypeData: DropdownListInfo[];
  supplyTypeData: DropdownListInfo[];
  tagData: TagListInfo[];
}

interface EditDataProps {
  editState: boolean;
  title: string;
  content:
    | IntermediaryCompanyInfo
    | ContactTypeListInfo
    | string
    | AddressInfo
    | DropdownListInfo
    | null
    | undefined
    | number[]
    | number;
}

function LeadEtc({
  data,
  refreshData,
  toggleSection,
  sectionStates,
  intermediaryData,
  saleTypeData,
  contactTypeData,
  contractTypeData,
  supplyTypeData,
  tagData,
}: LeadEtcProps) {
  const [isHovered, setIsHovered] = useState(false);
  // title: intermediaryCompany, saleType, contactType, contractType, supplyType, tags, estimatedDate, purchaseProbability
  const [editData, setEditData] = useState<EditDataProps>({
    editState: false,
    title: "",
    content: null,
  });
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openEdit = (type: string) => {
    if (type === "tags") {
      setSelectedTags(data?.tags ?? []);
      setEditData({ editState: true, title: type, content: null });
    } else {
      const existingInfo =
        type === "intermediaryCompany"
          ? data?.intermediaryCompany
          : type === "saleType"
          ? data?.saleType
          : type === "contactType"
          ? data?.contactType
          : type === "contractType"
          ? data?.contractType
          : type === "supplyType"
          ? data?.supplyType
          : type === "estimatedDate"
          ? data?.estimatedDate
          : type === "purchaseProbability"
          ? data?.purchaseProbability
          : "";

      setEditData({ editState: true, title: type, content: existingInfo });
    }
  };

  const processEditData = (dt: EditDataProps) => {
    if (dt.title === "tags") {
      return selectedTags
        .map((tag) => tag.id)
        .filter((id): id is number => id !== undefined);
    } else if (typeof dt.content === "string") {
      return { value: dt.content };
    } else {
      return dt.content;
    }
  };

  var submitcheck = false;
  const onSave = () => {
    if (submitcheck === false && data?.id) {
      submitcheck = true;
      CustomerApi.updateCustomerSingleFieldInfo(
        data.id,
        editData.title,
        processEditData(editData)
      )
        .then(() => {
          refreshData();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        })
        .finally(() => {
          submitcheck = false;
        });
    }
  };
  const onCancel = () => {
    setEditData({ editState: false, title: "", content: null });
  };

  // 중개업체
  const onFilteringIntermediary = (
    args: FilteringEventArgs,
    queryData: any
  ) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("address.placeName", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  const intermediaryFields: Object = {
    value: "id",
    text: "address.placeName",
    child: "children",
  };

  // 판매방식
  const saleTypeDataFields: Object = {
    dataSource: saleTypeData,
    value: "id",
    text: "name",
    child: "children",
  };
  const onFiltering = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("name", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  //영업방식
  const contactTypeDataFields: Object = {
    groupBy: "major",
    value: "id",
    text: "sub",
  };
  const onFilteringType = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("sub", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  // 수주유형
  const contractTypeFields: Object = {
    dataSource: contractTypeData,
    value: "id",
    text: "name",
    child: "children",
  };

  //공급유형
  const supplyTypeDataFields: Object = {
    value: "id",
    text: "name",
  };

  //태그
  const [selectedTags, setSelectedTags] = useState<TagListInfo[]>([]);
  const onChangeTag = (tagList: TagListInfo[]) => {
    setSelectedTags(tagList);
  };
  const onChange = (args: any, type: string) => {
    if (type === "dropdown") {
      const targetData = args.target.itemData;
      if (targetData) {
        setEditData({ ...editData, content: targetData });
      }
    } else if (type === "purchaseProbability") {
      const targetData = args.target.itemData;
      if (targetData) {
        setEditData({ ...editData, content: targetData.name });
      }
    } else if (type === "date") {
      setEditData({
        ...editData,
        content: isoDateFormatter(new Date(args.target.value)),
      });
    } else {
      setEditData({ ...editData, content: args.target.value });
    }
  };

  // 구매 가능성
  function itemTemplate(data: { id: number; name: number }) {
    return <>{data.name ? data.name.toString() + " %" : ""}</>;
  }

  function valueTemplate(data: { id: number; name: number }) {
    return (
      <CustomText type="subtitle" bold400>
        {data.name ? data.name + " %" : ""}
      </CustomText>
    );
  }
  const itemFields = { text: "name", value: "name" };

  return (
    <LeadTableCardBox style={{ marginTop: "20px" }}>
      <LeadTableTitleHover
        container
        item
        xs={12}
        onClick={() => toggleSection("LeadEtc")}
        justifyContent="space-between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CustomText type="subtitle">기타 정보</CustomText>
        {isHovered ? (
          <>{sectionStates["LeadEtc"] ? <ExpandLess /> : <ExpandMore />}</>
        ) : (
          ""
        )}
      </LeadTableTitleHover>
      {sectionStates["LeadEtc"] && (
        <LeadTableContentsBox container item xs={12}>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText type="subtitle" bold400 color={GRAYPALECONTENTS}>
                중개업체명
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "intermediaryCompany" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownListComponentBox
                    locale="ko"
                    showClearButton={true}
                    dataSource={intermediaryData}
                    filtering={(args: any) =>
                      onFilteringIntermediary(args, intermediaryData)
                    }
                    allowFiltering={true}
                    fields={intermediaryFields}
                    value={editData.content}
                    onChange={(e: any) => {
                      onChange(e, "dropdown");
                    }}
                    placeholder="중개업체를 선택해주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("intermediaryCompany")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.intermediaryCompany?.address?.placeName
                    ? data?.intermediaryCompany?.address?.placeName
                    : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText type="subtitle" bold400 color={GRAYPALECONTENTS}>
                판매방식
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "saleType" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownListComponentBox
                    locale="ko"
                    showClearButton={true}
                    dataSource={saleTypeData}
                    filtering={(args: any) => onFiltering(args, saleTypeData)}
                    allowFiltering={true}
                    fields={saleTypeDataFields}
                    value={editData.content}
                    onChange={(e: any) => {
                      onChange(e, "dropdown");
                    }}
                    placeholder="판매방식을 선택해주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("saleType")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.saleType?.name ? data?.saleType?.name : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>

          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                영업방식
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "contactType" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownListComponentBox
                    locale="ko"
                    showClearButton={true}
                    dataSource={contactTypeData}
                    filtering={(args: any) =>
                      onFilteringType(args, contactTypeData)
                    }
                    allowFiltering={true}
                    fields={contactTypeDataFields}
                    value={editData.content}
                    onChange={(e: any) => {
                      onChange(e, "dropdown");
                    }}
                    placeholder="영업방식을 선택해주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("contactType")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.contactType.major && data?.contactType.sub
                    ? "[" +
                      data?.contactType.major +
                      "] " +
                      data.contactType.sub
                    : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                수주유형
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "contractType" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownListComponentBox
                    locale="ko"
                    showClearButton={true}
                    dataSource={contractTypeData}
                    filtering={(args: any) =>
                      onFiltering(args, contractTypeData)
                    }
                    allowFiltering={true}
                    fields={contractTypeFields}
                    value={editData.content}
                    onChange={(e: any) => {
                      onChange(e, "dropdown");
                    }}
                    placeholder="수주유형을 선택해주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("contractType")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.contractType?.name ? data?.contractType.name : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6}>
            <LeadTableTitle item xs={4} pr="10px" alignItems="center">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                공급유형
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "supplyType" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownListComponentBox
                    locale="ko"
                    showClearButton={true}
                    dataSource={supplyTypeData}
                    filtering={(args: any) => onFiltering(args, supplyTypeData)}
                    allowFiltering={true}
                    fields={supplyTypeDataFields}
                    value={editData.content}
                    onChange={(e: any) => {
                      onChange(e, "dropdown");
                    }}
                    placeholder="공급유형을 선택해주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("supplyType")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.supplyType?.name ? data?.supplyType.name : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>

          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                태그
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "tags" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <SelectTag
                    list={selectedTags}
                    onChange={onChangeTag}
                    tagList={tagData}
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("tags")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.tags && data.tags.length > 0
                    ? data?.tags.map((tag) => tag.name).join(", ")
                    : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                구매 예상 시기
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "estimatedDate" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DatePickerComponent
                    name="estimatedDate"
                    format="yyyy-MM-dd"
                    locale="ko"
                    min={new Date()}
                    value={
                      editData.content && typeof editData.content === "string"
                        ? new Date(editData.content)
                        : undefined
                    }
                    onChange={(e: any) => {
                      onChange(e, "date");
                    }}
                    placeholder="구매 예상 시기를 선택해주세요."
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("estimatedDate")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.estimatedDate ? data?.estimatedDate : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                구매 가능성
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "purchaseProbability" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownListComponentBox
                    locale="ko"
                    showClearButton={true}
                    dataSource={PURCHASEPROBABILITY_TYPE}
                    itemTemplate={itemTemplate}
                    valueTemplate={valueTemplate}
                    fields={itemFields}
                    value={editData.content}
                    onChange={(e: any) => {
                      onChange(e, "purchaseProbability");
                    }}
                    placeholder="구매 가능성을 선택해주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("purchaseProbability")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.purchaseProbability
                    ? data?.purchaseProbability + " %"
                    : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} alignItems="center">
            <LeadTableTitle item xs={4} sm={2} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                비고
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "description" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <TextareaLineBox
                    name="description"
                    onChange={(e: any) => {
                      onChange(e, "input");
                    }}
                    value={
                      typeof editData.content === "string"
                        ? editData.content
                        : ""
                    }
                    placeholder="비고를 작성해 주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("description")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.description ? data?.description : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
        </LeadTableContentsBox>
      )}
    </LeadTableCardBox>
  );
}

export default LeadEtc;

const LeadTableContentStyled = styled(LeadTableContent)`
  position: relative;
  &:hover {
    cursor: pointer;
    border: 1px solid ${GRAYPALEBORDER};
    border-radius: 8px;
    & .editIcon {
      display: block;
    }
  }
`;

const EditIconStyled = styled(EditIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
`;
