import { Box, Grid, Modal } from "@mui/material";
import {
  ProductPricesInfo,
  SearchProductDataInfo,
} from "../../../../system/types/Product";
import {
  AdminGroupItemBoxContents,
  AdminGroupItemBoxContentsPadding,
  AdminGroupItemBoxNoMargin,
  AdminGroupItemBoxTitle,
} from "../../../../styles/AdminStyle";
import { CustomText } from "../../../../styles/CustomText";
import {
  CustomButtonGreen,
  CustomButtonSmallCommon,
  CustomButtonSmallRed,
  CustomButtonSmallYellow,
} from "../../../../styles/ButtonStyle";
import { useCallback, useEffect, useState } from "react";
import { ProductApi } from "../../../../system/api/ProductApi";
import { ErrorHandler } from "../../../../system/ApiService";
import AlertModal from "../../../../components/Common/AlertModal";
import { GroupInfo } from "../../../../system/types/Group";
import { GroupApi } from "../../../../system/api/GroupApi";
import {
  DropDownListComponentGridBox,
  DropDownTreeComponentBox,
  DropDownTreeComponentPlaceholderBox,
  InputBox,
  ModalStyle,
  PointerItem,
} from "../../../../styles/theme";
import uuid from "react-uuid";
import Swal from "sweetalert2";
import { GREENCONTENTS, GREENTITLE, REDTITLE } from "../../../../styles/Color";
import { CheckCircle, X } from "@phosphor-icons/react";
import { InitProductPriceInfoInfo } from "../../../../system/types/initObject";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
interface AdminPriceSettingProps {
  data: SearchProductDataInfo;
  openModal: (data: SearchProductDataInfo) => void;
}

function AdminProductSetting({ data, openModal }: AdminPriceSettingProps) {
  const preCurrencyData = require("currency-codes");
  const currencyData: string[] = preCurrencyData.codes();

  const [priceDataList, setPriceDataList] = useState<ProductPricesInfo[]>([]);
  const [priceData, setPriceData] = useState<ProductPricesInfo>(
    InitProductPriceInfoInfo
  );

  const refreshProductPriceData = useCallback(() => {
    if (data.id) {
      ProductApi.searchProductPrice(data.id)
        .then((res) => {
          setPriceDataList(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [data.id]);

  useEffect(() => {
    refreshProductPriceData();
  }, [refreshProductPriceData]);

  // 모달
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const handleClose = () => {
    setOpenPriceModal(false);
    setPriceData(InitProductPriceInfoInfo);
  };
  const handleOpen = () => {
    setOpenPriceModal(true);
    setPriceData({ ...priceData, productId: data.id });
  };

  const handleEditOpen = (pricedt: ProductPricesInfo) => {
    setOpenPriceModal(true);
    setPriceData(pricedt);
  };
  // 그룹정보
  const [groupData, setGroupData] = useState<GroupInfo[]>([]);

  useEffect(() => {
    GroupApi.getAllGroupInfo()
      .then((res) => {
        setGroupData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // let fields: Object = {
  //   dataSource: groupData,
  //   value: "id",
  //   text: "name",
  //   child: "children",
  // };

  let priceFields: Object = {
    dataSource: groupData,
    value: "id",
    text: "name",
    child: "children",
  };
  // const onChangeGroup = (args: any) => {
  //   setGroupId(args.value && args.value.length > 0 ? Number(args.value[0]) : 0);
  // };
  var submitcheck = false;
  const deleteGroupMember = (id: number | undefined | null) => {
    if (submitcheck === false && id) {
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 가격이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          ProductApi.deleteProductPrice(id)
            .then(() => {
              AlertModal("success", "선택하신 가격이 삭제되었습니다.");
              refreshProductPriceData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  };

  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setPriceData({
        ...priceData,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdown") {
      setPriceData({
        ...priceData,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    } else if (type === "dropdowntree") {
      setPriceData({
        ...priceData,
        groupId:
          args.value && args.value.length > 0 ? Number(args.value[0]) : 0,
      });
    }
  };
  function onSave() {
    submitcheck = true;
    if (submitcheck === true) {
      if (priceData.id) {
        ProductApi.updateProductPrice(priceData)
          .then(() => {
            AlertModal("success", "가격이 수정되었습니다.");
            handleClose();
            refreshProductPriceData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      } else {
        ProductApi.createProductPrice(priceData)
          .then(() => {
            handleClose();
            AlertModal("success", "가격이 추가되었습니다.");
            refreshProductPriceData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  }

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };

  const findNameById: any = (data: GroupInfo[], targetId: number) => {
    for (const item of data) {
      if (item.id === targetId) {
        return item.name;
      }

      if (item.children && item.children.length > 0) {
        const foundName = findNameById(item.children, targetId);
        if (foundName) {
          return foundName;
        }
      }
    }

    return null;
  };

  return (
    <>
      <Grid container justifyContent="space-between" mb="10px">
        <Grid item xs={12} sm={5.9}>
          <AdminGroupItemBoxNoMargin>
            <AdminGroupItemBoxTitle
              paddingnumber="6px 10px"
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid container item xs={6}>
                <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                  정보
                </CustomText>
              </Grid>
              <Grid container item justifyContent="right" xs={6}>
                <CustomButtonSmallCommon onClick={() => openModal(data)}>
                  수정
                </CustomButtonSmallCommon>
              </Grid>
            </AdminGroupItemBoxTitle>
            {data.id ? (
              <>
                <AdminGroupItemBoxContents container alignItems="center">
                  <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                    제품군
                  </CustomText>
                  <CustomText type="subtitle" bold500 color="#3D4861">
                    {data.family}
                  </CustomText>
                </AdminGroupItemBoxContents>
                <AdminGroupItemBoxContents container alignItems="center">
                  <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                    제품명
                  </CustomText>
                  <CustomText type="subtitle" bold500 color="#3D4861">
                    {data.name}
                  </CustomText>
                </AdminGroupItemBoxContents>
                <AdminGroupItemBoxContents container alignItems="center">
                  <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                    출시일
                  </CustomText>
                  <CustomText type="subtitle" bold500 color="#3D4861">
                    {data.launchDate}
                  </CustomText>
                </AdminGroupItemBoxContents>
                <AdminGroupItemBoxContents container alignItems="center">
                  <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                    단종일
                  </CustomText>
                  <CustomText type="subtitle" bold500 color="#3D4861">
                    {data.discontinuanceDate}
                  </CustomText>
                </AdminGroupItemBoxContents>
                <AdminGroupItemBoxContents container alignItems="center">
                  <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                    사용 가능 여부
                  </CustomText>
                  <CustomText type="subtitle" bold500 color="#3D4861">
                    {data.enabled === true ? "가능" : "불가"}
                  </CustomText>
                </AdminGroupItemBoxContents>
              </>
            ) : (
              <>
                <Grid container justifyContent="center" margin="10px">
                  <CustomText
                    type="subtitle"
                    textAlign="center"
                    color="#3D4861"
                  >
                    해당 정보가 없습니다.
                  </CustomText>
                </Grid>
              </>
            )}
          </AdminGroupItemBoxNoMargin>
        </Grid>
        <Grid item xs={12} sm={5.9}>
          <AdminGroupItemBoxNoMargin>
            <AdminGroupItemBoxTitle
              paddingnumber="6px 10px"
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid container item xs={6}>
                <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
                  가격
                </CustomText>
              </Grid>
              <Grid container item justifyContent="right" xs={6}>
                <CustomButtonSmallCommon onClick={handleOpen}>
                  추가
                </CustomButtonSmallCommon>
              </Grid>
            </AdminGroupItemBoxTitle>
            <Grid container justifyContent="right"></Grid>
            {priceDataList.length > 0 ? (
              <>
                {priceDataList.map((dt: ProductPricesInfo) => {
                  return (
                    <Grid item xs={12} key={uuid()}>
                      <AdminGroupItemBoxContentsPadding
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid container item xs={12}>
                          {findNameById(groupData, dt.groupId)}
                        </Grid>
                        <Grid container item xs={12} sm={6}>
                          <Grid container>
                            <Grid container justifyContent="center" item xs={6}>
                              {" "}
                              <CustomText
                                type="subtitle"
                                bold400
                                mr="10px"
                                color="#3D4861"
                              >
                                권장 소비자가
                              </CustomText>
                            </Grid>
                            <Grid container justifyContent="right" item xs={6}>
                              <CustomText
                                type="subtitle"
                                bold500
                                color="#3D4861"
                                mr="10px"
                              >
                                {dt.retailPrice?.toLocaleString() + " 원"}
                              </CustomText>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid container justifyContent="center" item xs={6}>
                              {" "}
                              <CustomText
                                type="subtitle"
                                bold400
                                mr="10px"
                                color="#3D4861"
                              >
                                딜러가
                              </CustomText>
                            </Grid>
                            <Grid container justifyContent="right" item xs={6}>
                              <CustomText
                                type="subtitle"
                                bold500
                                color="#3D4861"
                                mr="10px"
                              >
                                {dt.wholesalePrice
                                  ? dt.wholesalePrice?.toLocaleString() + " 원"
                                  : "-"}
                              </CustomText>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid container justifyContent="center" item xs={6}>
                              {" "}
                              <CustomText
                                type="subtitle"
                                bold400
                                mr="10px"
                                color="#3D4861"
                              >
                                파트너가
                              </CustomText>
                            </Grid>
                            <Grid container justifyContent="right" item xs={6}>
                              <CustomText
                                type="subtitle"
                                bold500
                                color="#3D4861"
                                mr="10px"
                              >
                                {dt.partnerPrice
                                  ? dt.partnerPrice?.toLocaleString() + " 원"
                                  : "-"}
                              </CustomText>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent="right"
                          item
                          xs={12}
                          sm={6}
                        >
                          <CustomButtonSmallYellow
                            style={{ marginRight: "5px" }}
                            onClick={() => handleEditOpen(dt)}
                          >
                            수정
                          </CustomButtonSmallYellow>
                          <CustomButtonSmallRed
                            onClick={() => {
                              deleteGroupMember(dt.id);
                            }}
                          >
                            삭제
                          </CustomButtonSmallRed>
                        </Grid>
                      </AdminGroupItemBoxContentsPadding>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <Grid container justifyContent="center" margin="10px">
                <CustomText type="subtitle" textAlign="center" color="#3D4861">
                  가격을 추가해주세요.
                </CustomText>
              </Grid>
            )}
          </AdminGroupItemBoxNoMargin>
        </Grid>
        <Modal
          open={openPriceModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <Grid container spacing={2}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                item
                xs={12}
              >
                {priceData && priceData.id ? (
                  <CustomText type="title"> 정보 수정</CustomText>
                ) : (
                  <CustomText type="title"> 제품 추가</CustomText>
                )}

                <PointerItem onClick={handleClose}>
                  <X size={32} />
                </PointerItem>
              </Grid>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <Grid mr="5px">소속 그룹</Grid>
                  {!priceData.groupId ? (
                    <CustomText type="small" color={REDTITLE}>
                      * 소속 그룹을 선택해주세요.
                    </CustomText>
                  ) : (
                    <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                  )}
                </Grid>
                <DropDownListComponentGridBox>
                  {priceData.id ? (
                    <DropDownTreeComponentPlaceholderBox
                      showClearButton={false}
                      fields={priceFields}
                      changeOnBlur={false}
                      change={(e: any) => {
                        onChange(e, "dropdowntree");
                      }}
                      placeholder={
                        priceData.id
                          ? findNameById(groupData, priceData.groupId)
                          : "소속 그룹"
                      }
                      autoComplete="new-password"
                    />
                  ) : (
                    <DropDownTreeComponentBox
                      showClearButton={false}
                      fields={priceFields}
                      changeOnBlur={false}
                      change={(e: any) => {
                        onChange(e, "dropdowntree");
                      }}
                      placeholder={
                        priceData.id
                          ? findNameById(groupData, priceData.groupId)
                          : "소속 그룹"
                      }
                      autoComplete="new-password"
                    />
                  )}
                </DropDownListComponentGridBox>
              </Grid>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <Grid mr="5px">통화</Grid>
                  {priceData.currency === "" ? (
                    <CustomText type="small" color={REDTITLE}>
                      * 통화를 선택해주세요.
                    </CustomText>
                  ) : (
                    <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                  )}
                </Grid>
                <DropDownListComponentGridBox>
                  <DropDownListComponent
                    dataSource={currencyData}
                    name="currency"
                    value={priceData.currency}
                    onChange={(e: any) => {
                      onChange(e, "dropdown");
                    }}
                    placeholder="통화를 선택해주세요."
                  />
                </DropDownListComponentGridBox>
              </Grid>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <Grid mr="5px">권장 소비자가</Grid>
                  {priceData.retailPrice ? (
                    <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                  ) : (
                    <CustomText type="small" color={REDTITLE}>
                      * 권장 소비자가를 입력해 주세요.
                    </CustomText>
                  )}
                </Grid>
                <InputBox
                  type="text"
                  name="retailPrice"
                  onChange={(e: any) => {
                    onChange(e, "input");
                  }}
                  value={priceData.retailPrice || ""}
                  placeholder="권장 소비자가"
                  onKeyDown={handleOnKeyDown}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <Grid mr="5px">딜러가</Grid>
                  {priceData.wholesalePrice ? (
                    <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                  ) : (
                    <CustomText type="small" color={REDTITLE}>
                      * 딜러가를 입력해 주세요.
                    </CustomText>
                  )}
                </Grid>
                <InputBox
                  type="text"
                  name="wholesalePrice"
                  onChange={(e: any) => {
                    onChange(e, "input");
                  }}
                  value={priceData.wholesalePrice || ""}
                  placeholder="딜러가"
                  onKeyDown={handleOnKeyDown}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <Grid mr="5px">파트너가</Grid>
                  {priceData.partnerPrice ? (
                    <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                  ) : (
                    <CustomText type="small" color={REDTITLE}>
                      * 파트너가를 입력해 주세요.
                    </CustomText>
                  )}
                </Grid>
                <InputBox
                  type="text"
                  name="partnerPrice"
                  onChange={(e: any) => {
                    onChange(e, "input");
                  }}
                  value={priceData.partnerPrice || ""}
                  placeholder="파트너가"
                  onKeyDown={handleOnKeyDown}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="right" mt="20px">
              <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </>
  );
}

export default AdminProductSetting;
