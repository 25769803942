import { useEffect, useState } from "react";
import { GroupStatisticsGroupsInfo } from "../../../system/types/Statistics";
import { StatisticsApi } from "../../../system/api/StatisticsApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../../components/Common/AlertModal";
import { Grid, styled } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import { DropDownListComponentBox } from "../../../styles/theme";
import { MONTH_LIST } from "../../../system/Constants";
import {
  TableMainContentBox,
  TableMainNumberContentBox,
  TableMainTitleBox,
  TableOverflow,
} from "../../../styles/StatisticsTableStyle";
import uuid from "react-uuid";
interface BranchForecastProps {
  yearList: string[];
}

function BranchForecast({ yearList }: BranchForecastProps) {
  const [branchForecastData, setBranchForecastData] = useState<{
    groups: GroupStatisticsGroupsInfo[];
    employees: any;
  }>({ groups: [], employees: {} });
  const [searchYear, setSearchYear] = useState(
    new Date().getFullYear().toString()
  );
  const [searchMonth, setSearchMonth] = useState((1).toString());
  const [searchGap, setSearchGap] = useState("12");
  const [titleList, setTitleList] = useState<string[]>([]);
  useEffect(() => {
    const customDateList = [];
    for (var i = 0; i < Number(searchGap); i++) {
      if (Number(searchMonth) + i > 12) {
        customDateList.push(
          `${Number(searchYear) + 1}/${Number(searchMonth) + i - 12}`
        );
      } else {
        customDateList.push(`${searchYear}/${Number(searchMonth) + i}`);
      }
    }
    setTitleList(customDateList);
  }, [searchYear, searchMonth, searchGap]);

  const onChangeDate = (args: any, type: string) => {
    if (type === "year") {
      setSearchYear(args.target.value.toString());
    } else if (type === "month") {
      setSearchMonth(args.target.value.toString());
    } else if (type === "gap") {
      setSearchGap(args.target.value.toString());
    }
  };

  useEffect(() => {
    StatisticsApi.branchForecastStatistics(
      Number(searchYear),
      Number(searchMonth),
      Number(searchGap)
    )
      .then((res) => {
        setBranchForecastData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchGap, searchMonth, searchYear]);

  const combineValues = (obj: any) => {
    return Object.values(obj).join("");
  };

  function yearValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}년
        </CustomText>
      </Grid>
    );
  }

  function monthValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}월 부터
        </CustomText>
      </Grid>
    );
  }

  function gapValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}개월
        </CustomText>
      </Grid>
    );
  }

  function formatNumberToBillions(num: number) {
    if (isNaN(num)) {
      return "유효한 숫자가 아닙니다.";
    }

    return (num / 1e8).toFixed(2);
  }

  return (
    <Grid container justifyContent="center">
      <Grid container justifyContent="right" item xs={12} mb="10px">
        <DropdownBox mr="5px">
          <DropDownListComponentBox
            popupWidth={120}
            dataSource={yearList}
            placeholder="년도"
            onChange={(e: any) => {
              onChangeDate(e, "year");
            }}
            value={searchYear.toString()}
            valueTemplate={yearValueTemplate}
          />
        </DropdownBox>
        <DropdownBox mr="5px">
          <DropDownListComponentBox
            popupWidth={120}
            dataSource={MONTH_LIST}
            placeholder="월"
            onChange={(e: any) => {
              onChangeDate(e, "month");
            }}
            value={searchMonth.toString()}
            valueTemplate={monthValueTemplate}
          />
        </DropdownBox>
        <DropdownBox>
          <DropDownListComponentBox
            popupWidth={120}
            dataSource={MONTH_LIST}
            placeholder="기간"
            onChange={(e: any) => {
              onChangeDate(e, "gap");
            }}
            value={searchGap.toString()}
            valueTemplate={gapValueTemplate}
          />
        </DropdownBox>
      </Grid>
      <Grid container justifyContent="right" item xs={12} mb="5px">
        (단위: 억원)
      </Grid>
      <TableMainTitleBox container alignItems="center">
        <Grid
          item
          xs={12 / (Number(searchGap) + 1)}
          container
          justifyContent="center"
        >
          <CustomText type="body">구분</CustomText>
        </Grid>
        {titleList.map((datedt: string) => (
          <TableOverflow
            item
            xs={12 / (Number(searchGap) + 1)}
            key={uuid()}
            container
            alignItems="center"
            justifyContent="right"
            pr="5px"
          >
            <CustomText type="body">{datedt}</CustomText>
          </TableOverflow>
        ))}
      </TableMainTitleBox>
      {branchForecastData.groups.map((child: GroupStatisticsGroupsInfo) => (
        <Grid container key={uuid()} alignItems="center">
          <TableMainContentBox container>
            <TableOverflow
              container
              item
              xs={12 / (Number(searchGap) + 1)}
              alignItems="center"
              justifyContent="center"
            >
              <CustomText type="body">{child.groupName}</CustomText>
            </TableOverflow>
            {child.monthTotal.map((item: number) => (
              <TableMainNumberContentBox
                item
                container
                alignItems="center"
                justifyContent="right"
                xs={12 / (Number(searchGap) + 1)}
                key={uuid()}
                pr="5px"
              >
                <CustomText type="body">
                  {formatNumberToBillions(item)}
                </CustomText>
              </TableMainNumberContentBox>
            ))}
          </TableMainContentBox>
        </Grid>
      ))}
    </Grid>
  );
}

export default BranchForecast;

const DropdownBox = styled(Grid)({
  width: "120px",
});
