import {
  BigModalStyle,
  LeadTableCardBox,
  LeadTableCardBoxScroll,
  LeadTableContentsBox,
  PointerItem,
} from "../../../../../styles/theme";
import { LeadCustomerDetailInfo } from "../../../../../system/types/Customer";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { StepIconProps } from "@mui/material/StepIcon";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BalanceIcon from "@mui/icons-material/Balance";
import CancelIcon from "@mui/icons-material/Cancel";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { X } from "@phosphor-icons/react";
import { CustomText } from "../../../../../styles/CustomText";
import { DropdownListInfo } from "../../../../../system/types/List";
import uuid from "react-uuid";
import { GRAYPALETITLE, PRIMARYHOVERED } from "../../../../../styles/Color";
import { Box, Grid, Modal } from "@mui/material";
import { useState } from "react";
import { CustomButtonGreen } from "../../../../../styles/ButtonStyle";
import { SalesActivityInfo } from "../../../../../system/types/SalesActivity";
import { isoDateFormatter } from "../../../../../components/Common/IsoDateFormatter";
import {
  InitDropdownListInfo,
  InitSalesActivityInfo,
} from "../../../../../system/types/initObject";
import ActivityCreate from "../WorkHistory/ActivityCreate";
import ActivityContent from "../WorkHistory/ActivityContent";
import AlertModal from "../../../../../components/Common/AlertModal";
import { SalesActivityApi } from "../../../../../system/api/SalesActivityApi";
import { ErrorHandler } from "../../../../../system/ApiService";
import { CustomerApi } from "../../../../../system/api/CustomerApi";

interface LeadStepProps {
  data: LeadCustomerDetailInfo | null;
  stepData: DropdownListInfo[];
  refreshData: () => void;
  refreshActivityData: () => void;
}
function validation(data: SalesActivityInfo) {
  if (data.title === "") {
    AlertModal("check", "제목을 작성해 주세요.");
    return false;
  } else if (data.type === "") {
    AlertModal("check", "유형을 작성해 주세요.");
    return false;
  } else if (data.activityDate === "") {
    AlertModal("check", "작업일을 작성해 주세요.");
    return false;
  } else if (data.followUpDate === "") {
    AlertModal("check", "후속 조치 일자를 작성해 주세요.");
    return false;
  } else if (data.content === "") {
    AlertModal("check", "내용을 작성해 주세요.");
    return false;
  } else if (data.nextAction === "") {
    AlertModal("check", "다음 작업을 작성해 주세요.");
    return false;
  }
  return true;
}

function LeadStep({
  data,
  stepData,
  refreshData,
  refreshActivityData,
}: LeadStepProps) {
  const selectedId = stepData.findIndex(
    (item) => item.name === data?.customer?.step?.name
  );
  const [salesActivityData, setSalesActivityData] = useState<SalesActivityInfo>(
    {
      ...InitSalesActivityInfo,
      customerId: data?.customer.id ? data?.customer.id : null,
      title: data?.customer.address.placeName
        ? data?.customer.address.placeName + "_" + isoDateFormatter(new Date())
        : "",
    }
  );
  const [open, setOpen] = useState(false);
  const [changeData, setChangeData] =
    useState<DropdownListInfo>(InitDropdownListInfo);
  const handleOpen = (dt: DropdownListInfo) => {
    setOpen(true);
    setChangeData(dt);
  };
  const handleClose = () => {
    setOpen(false);
    setChangeData(InitDropdownListInfo);
    setSalesActivityData({
      ...InitSalesActivityInfo,
      customerId: data?.customer.id ? data?.customer.id : null,
      title: data?.customer.address.placeName
        ? data?.customer.address.placeName + "_" + isoDateFormatter(new Date())
        : "",
    });
  };
  var submitcheck = false;
  function onSave() {
    if (
      validation(salesActivityData) &&
      changeData?.id &&
      submitcheck === false
    ) {
      submitcheck = true;
      SalesActivityApi.createSalesActivity({
        salesActivity: salesActivityData,
        changedcustomerStepId: changeData.id,
      })
        .then(() => {
          AlertModal("success", "작업이력이 생성되었습니다.");
          setOpen(false);
          setSalesActivityData({
            ...InitSalesActivityInfo,
            customerId: data?.customer.id ? data?.customer.id : null,
            title: data?.customer.address.placeName
              ? data?.customer.address.placeName +
                "_" +
                isoDateFormatter(new Date())
              : "",
          });
          refreshData();
          refreshActivityData();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          submitcheck = false;
        });
    }
  }

  return (
    <>
      {selectedId !== null && (
        <LeadTableCardBoxScroll>
          <Stepper
            alternativeLabel
            activeStep={selectedId}
            connector={<ColorlibConnector />}
          >
            {stepData.map((item, index) => (
              <Step key={uuid()}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  style={{
                    cursor: index > selectedId ? "pointer" : "default",
                  }}
                  onClick={() => {
                    index > selectedId ? handleOpen(item) : setOpen(false);
                  }}
                >
                  <CustomText type="body">{item.name}</CustomText>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </LeadTableCardBoxScroll>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={BigModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title">작업 이력 생성</CustomText>
              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid container item xs={12}>
              <LeadTableCardBox mt="20px">
                <Grid container item xs={12} alignItems="center" padding="10px">
                  <CustomText type="subtitle">영업 단계 변경</CustomText>
                </Grid>
                <LeadTableContentsBox
                  container
                  item
                  xs={12}
                  alignItems="center"
                >
                  <CustomText
                    type="subtitle"
                    color={GRAYPALETITLE}
                    marginLeft="10px"
                  >
                    {data?.customer?.step?.name}
                  </CustomText>
                  에서
                  <CustomText
                    type="subtitle"
                    color={GRAYPALETITLE}
                    marginLeft="10px"
                  >
                    {changeData.name}
                  </CustomText>
                  (으)로 변경
                </LeadTableContentsBox>
              </LeadTableCardBox>
              <ActivityCreate
                salesActivityData={salesActivityData}
                setSalesActivityData={setSalesActivityData}
                activityModalState="create"
              />

              <ActivityContent
                salesActivityData={salesActivityData}
                setSalesActivityData={setSalesActivityData}
                activityModalState="create"
              />
            </Grid>
            <Grid container item xs={12} justifyContent="right">
              <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default LeadStep;

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#971B2F 0%,#971B2F 50%,#971B2F 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#971B2F 0%,#971B2F 50%,#971B2F 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #971B2F 0%, #971B2F 50%, #971B2F 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #971B2F 0%, #971B2F 50%, #971B2F 100%)",
  }),
  "&:hover": {
    backgroundColor: PRIMARYHOVERED,
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonAddAlt1Icon />,
    2: <QuestionAnswerIcon />,
    3: <ContentPasteIcon />,
    4: <Diversity3Icon />,
    5: <BalanceIcon />,
    6: <CancelIcon />,
    7: <CheckCircleIcon />,
    8: <ManageAccountsIcon />,
    9: <DeleteOutlineIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
