import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { CustomButtonGreen } from "../../../styles/ButtonStyle";
import { useEffect, useState } from "react";
import {
  LeadCustomerCreateInfo,
  LeadOrdersInfo,
} from "../../../system/types/Customer";
import {
  InitContactTypeListInfo,
  InitLeadCustomerCreateInfo,
} from "../../../system/types/initObject";
import AlertModal from "../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../system/ApiService";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  CSState,
  ManagedGroupDataState,
  MyInfoState,
  registerLeadStateAtom,
} from "../../../system/atoms";
import { EmployeeTableInfo } from "../../../system/types/Employee";
import { GroupInfo } from "../../../system/types/Group";
import { GroupApi } from "../../../system/api/GroupApi";
import { TokenApi } from "../../../system/api/TokenApi";

import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import EditLeadInfo from "./Components/Edit/EditLeadInfo";
import EditLeadEtc from "./Components/Edit/EditLeadEtc";
import EditLeadBranch from "./Components/Edit/EditLeadBranch";
import { TagListInfo } from "../../../system/types/List";
import { CustomText } from "../../../styles/CustomText";
import { CustomerApi } from "../../../system/api/CustomerApi";
interface Params {
  id: string;
}

function Validation(
  data: LeadCustomerCreateInfo,
  directInput: boolean,
  directWritePlaceName: string,
  csValue: boolean
) {
  // eslint-disable-next-line
  const exptext = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/;
  // eslint-disable-next-line
  const exptextMail = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

  if (data.customerType === "일반") {
    if (
      (!directInput && !data.address.placeName) ||
      (directInput && directWritePlaceName === "")
    ) {
      AlertModal("check", "상호명을 입력해 주세요.");
      return false;
    } else if (!csValue && !data.businessType?.id) {
      AlertModal("check", "업종을 선택해주세요.");
      return false;
    } else if (
      data.businessType &&
      (data.businessType.name === "양방종합병원" ||
        data.businessType.name === "한방병원") &&
      !data.department?.id
    ) {
      AlertModal("check", "과/팀/센터를 선택해주세요.");
      return false;
    } else if (!data.saleType?.id && !csValue) {
      AlertModal("check", "판매방식을 선택해주세요.");
      return false;
    } else if (!data.contactType?.id) {
      AlertModal("check", "영업방식을 선택해주세요.");
      return false;
    } else if (!data.step?.id) {
      AlertModal("check", "영업단계를 선택해주세요.");
      return false;
    } else if (data.email && exptextMail.test(data.email) === false) {
      AlertModal("check", "이메일을 올바른 형식으로 작성해 주세요.");
      return false;
    } else if (data.phone && exptext.test(data.phone) === false) {
      AlertModal(
        "check",
        "연락처를 올바른 형식으로 작성해 주세요. \n ex. 010-0000-0000"
      );
      return false;
    }
  } else {
    if (
      (!directInput && !data.address.placeName) ||
      (directInput && directWritePlaceName === "")
    ) {
      AlertModal("check", "상호명을 입력해 주세요.");
      return false;
    } else if (!data.contactType.id) {
      AlertModal("check", "영업방식을 선택해주세요.");
      return false;
    } else if (data.email && exptextMail.test(data.email) === false) {
      AlertModal("check", "이메일을 올바른 형식으로 작성해 주세요.");
      return false;
    } else if (data.phone && exptext.test(data.phone) === false) {
      AlertModal(
        "check",
        "연락처를 올바른 형식으로 작성해 주세요. \n ex. 010-0000-0000"
      );
      return false;
    }
  }

  return true;
}

function ByCaseRegisterPage() {
  const { id } = useParams<Params>();
  const leadId = parseInt(id);
  const history = useHistory();
  const [data, setData] = useState<LeadCustomerCreateInfo>(
    InitLeadCustomerCreateInfo
  );

  const groupData = useRecoilValue<GroupInfo[]>(ManagedGroupDataState);
  const [employeeList, setEmployeeList] = useState<EmployeeTableInfo[]>([]);
  const myInfoData = useRecoilValue(MyInfoState);
  const [RegisterLeadState, setRegisterLeadState] = useRecoilState(
    registerLeadStateAtom
  );
  const [directWritePlaceName, setDirectWritePlaceName] = useState("");
  const [directInput, setDirectInput] = useState(false);
  const CSValue = useRecoilValue(CSState);
  useEffect(() => {
    if (groupData.length > 0 && groupData[0] && groupData[0]?.id) {
      if (
        myInfoData.groups &&
        myInfoData.groups.length > 0 &&
        myInfoData.groups[0]?.id &&
        myInfoData.id
      ) {
        GroupApi.findGroupEmployee(myInfoData.groups[0]?.id)
          .then((resEmp) => {
            setEmployeeList(resEmp.data);
            setData((prev: LeadCustomerCreateInfo) => ({
              ...prev,
              groupId: myInfoData.groups[0]?.id || null,
              sellerId: myInfoData.id,
            }));
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    }
  }, [leadId, RegisterLeadState, groupData, myInfoData.groups, myInfoData.id]);

  // token
  const [sasToken, setSasToken] = useState("");

  useEffect(() => {
    TokenApi.getSasToken()
      .then((res) => {
        setSasToken(res.data.sasUrl);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  var submitcheck = false;
  const onSave = () => {
    if (Validation(data, directInput, directWritePlaceName, CSValue)) {
      submitcheck = true;
      if (submitcheck === true) {
        const placeNameInfo =
          directInput === true
            ? directWritePlaceName
            : data?.address?.placeName;
        CustomerApi.createCustoemrInfo({
          ...data,
          address: { ...data.address, placeName: placeNameInfo },
          tagIds: selectedTags
            .map((tag) => tag.id)
            .filter((id): id is number => id !== undefined),
        })
          .then((res) => {
            setRegisterLeadState("");
            AlertModal(
              "success",
              data.customerType === "일반"
                ? "Pipeline이 등록되었습니다."
                : "중개업체가 등록되었습니다."
            );
            setData(InitLeadCustomerCreateInfo);
            history.push(`/list/detail/${res.data.id}`);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value === "중개업체") {
      setData({
        ...data,
        customerType: (event.target as HTMLInputElement).value,
        intermediaryCompanyId: null,
        businessType: null,
        department: null,
        saleType: null,
        contactType: InitContactTypeListInfo,
        contractType: null,
        supplyType: null,
        step: null,
        estimatedDate: "",
      });
    } else {
      setData({
        ...data,
        customerType: (event.target as HTMLInputElement).value,
      });
    }
  };
  const [tagData, setTagData] = useState<TagListInfo[]>([]);
  const [selectedTags, setSelectedTags] = useState<TagListInfo[]>([]);

  return (
    <>
      <CustomText type="title">건별 등록</CustomText>
      <Grid container justifyContent="right" margin="5px 0px">
        <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
      </Grid>

      <Grid>
        <RadioGroup row onChange={handleChange} value={data.customerType}>
          <FormControlLabel
            value="일반"
            control={<Radio color="default" />}
            label="앤드유저"
          />
          <FormControlLabel
            value="중개업체"
            control={<Radio color="default" />}
            label="중개업체"
          />
        </RadioGroup>
      </Grid>
      {data.customerType === "일반" && (
        <Grid item xs={12} md={11.7}>
          <MessageComponent content="간판의 경우, 중개업체를 먼저 선택해주세요. 중개업체가 없는 경우, 중개업체 추가 후 진행해주세요." />
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <EditLeadInfo
            data={data}
            leadId={leadId}
            setData={setData}
            onSave={onSave}
            directWritePlaceName={directWritePlaceName}
            setDirectWritePlaceName={setDirectWritePlaceName}
            directInput={directInput}
            setDirectInput={setDirectInput}
          />
        </Grid>
        <Grid item xs={12}>
          <EditLeadEtc
            data={data}
            leadId={leadId}
            setData={setData}
            onSave={onSave}
            directWritePlaceName={directWritePlaceName}
            setDirectWritePlaceName={setDirectWritePlaceName}
            directInput={directInput}
            setDirectInput={setDirectInput}
            tagData={tagData}
            setTagData={setTagData}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        </Grid>
        <Grid item xs={12} mt="10px">
          <EditLeadBranch
            data={data}
            setData={setData}
            employeeList={employeeList}
            setEmployeeList={setEmployeeList}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ByCaseRegisterPage;
