import { Grid } from "@mui/material";
import {
  EditInputSmallBox,
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  TableFixedHeight,
  TextareaLineBox,
} from "../../../../../styles/theme";
import { SalesActivityInfo } from "../../../../../system/types/SalesActivity";
import { CustomText } from "../../../../../styles/CustomText";
import {
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  REDTITLE,
} from "../../../../../styles/Color";

interface ActivityContentProps {
  salesActivityData: SalesActivityInfo;
  setSalesActivityData: (salesActivityData: SalesActivityInfo) => void;
  activityModalState: string;
}

function ActivityContent({
  salesActivityData,
  setSalesActivityData,
  activityModalState,
}: ActivityContentProps) {
  const onChange = (args: any) => {
    setSalesActivityData({
      ...salesActivityData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  return (
    <LeadTableCardBox mt="20px">
      <Grid container item xs={12} alignItems="center" padding="10px">
        <CustomText type="subtitle" lineSpace>
          작업 내용
        </CustomText>
        <CustomText fontSize="small" color={REDTITLE}>
          *
        </CustomText>
      </Grid>

      <LeadTableContentsBox container item xs={12}>
        <TableFixedHeight container item xs={12} alignItems="flex-start">
          <LeadTableTitle item xs={2}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              내용
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent item xs={10} pl="20px" pr="10px">
            {activityModalState === "detail" ? (
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                style={{
                  overflow: "auto",
                  overflowWrap: "anywhere",
                  whiteSpace: "pre-line",
                }}
              >
                {salesActivityData?.content ? salesActivityData?.content : "-"}
              </CustomText>
            ) : (
              <TextareaLineBox
                name="content"
                onChange={onChange}
                value={salesActivityData.content || ""}
                placeholder="내용을 작성해 주세요."
                autoComplete="new-password"
              />
            )}
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} alignItems="flex-start">
          <LeadTableTitle item xs={2}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              다음 작업
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent item xs={10} pl="20px" pr="10px">
            {activityModalState === "detail" ? (
              <CustomText
                type="subtitle"
                bold400
                style={{
                  overflow: "auto",
                  overflowWrap: "anywhere",
                  whiteSpace: "pre-line",
                }}
                color={GRAYPALETITLE}
              >
                {salesActivityData?.nextAction
                  ? salesActivityData?.nextAction
                  : "-"}
              </CustomText>
            ) : (
              <TextareaLineBox
                name="nextAction"
                onChange={onChange}
                value={salesActivityData.nextAction || ""}
                placeholder="다음 작업을 작성해 주세요."
                autoComplete="new-password"
              />
            )}
          </LeadTableContent>
        </TableFixedHeight>
      </LeadTableContentsBox>
    </LeadTableCardBox>
  );
}

export default ActivityContent;
