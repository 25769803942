import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  styled,
} from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { CustomerApi } from "../../../system/api/CustomerApi";
import {
  IntermediaryCompanyInfo,
  LeadCustomerDetailInfo,
} from "../../../system/types/Customer";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../../components/Common/AlertModal";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AdminState,
  MyInfoState,
  registerLeadStateAtom,
} from "../../../system/atoms";

import { HistoryInfo } from "../../../system/types/History";
import Swal from "sweetalert2";
import {
  GRAYPALECONTENTS,
  GREENCONTENTS,
  PRIMARY,
  PRIMARYHOVERED,
  REDTITLE,
} from "../../../styles/Color";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { GroupInfo } from "../../../system/types/Group";
import CheckIcon from "@mui/icons-material/CheckBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LeadDetailMain from "./Components/Detail/LeadDetailMain";
import LeadWorkHistory from "./Components/WorkHistory/LeadWorkHistory";
import LeadHistory from "./Components/History/LeadHistory";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { HistoryApi } from "../../../system/api/HistoryApi";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ProductApi } from "../../../system/api/ProductApi";
import { TokenApi } from "../../../system/api/TokenApi";
import {
  SalesActivityDetailInfo,
  SalesActivityInfo,
} from "../../../system/types/SalesActivity";
import { SalesActivityApi } from "../../../system/api/SalesActivityApi";
import {
  ContactTypeListInfo,
  DropdownListInfo,
  TagListInfo,
} from "../../../system/types/List";
import { ListApi } from "../../../system/api/ListApi";
import { BigModalStyle, PointerItem } from "../../../styles/theme";
import { InitSalesActivityInfo } from "../../../system/types/initObject";
import { isoDateFormatter } from "../../../components/Common/IsoDateFormatter";
import { X } from "@phosphor-icons/react";
import ActivityCreate from "./Components/WorkHistory/ActivityCreate";
import ActivityContent from "./Components/WorkHistory/ActivityContent";
import { CustomButtonGreen } from "../../../styles/ButtonStyle";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { TagApi } from "../../../system/api/TagApi";
import { GroupApi } from "../../../system/api/GroupApi";
interface Params {
  id: string;
}
function validation(data: SalesActivityInfo) {
  if (data.title === "") {
    AlertModal("check", "제목을 작성해 주세요.");
    return false;
  } else if (data.type === "") {
    AlertModal("check", "유형을 작성해 주세요.");
    return false;
  } else if (data.activityDate === "") {
    AlertModal("check", "작업일을 작성해 주세요.");
    return false;
  } else if (data.followUpDate === "") {
    AlertModal("check", "후속 조치 일자를 작성해 주세요.");
    return false;
  } else if (data.content === "") {
    AlertModal("check", "내용을 작성해 주세요.");
    return false;
  } else if (data.nextAction === "") {
    AlertModal("check", "다음 작업을 작성해 주세요.");
    return false;
  }
  return true;
}
function LeadDetailPage() {
  const { id } = useParams<Params>();
  const detailId = parseInt(id);
  const [data, setData] = useState<LeadCustomerDetailInfo | null>(null);
  const history = useHistory();
  const adminValue = useRecoilValue(AdminState);
  const myInfoData = useRecoilValue(MyInfoState);
  const [, setRegisterLeadState] = useRecoilState(registerLeadStateAtom);
  const [protectionStatus, setProtectionStatus] = useState(false);

  const containsDataId = (
    items: GroupInfo[],
    id: number | null | undefined
  ) => {
    for (let item of items) {
      if (item.id === id) {
        return true;
      }
      if (item.children && containsDataId(item.children, id)) {
        return true;
      }
    }
    return false;
  };

  var submitcheck = false;

  function deleteCustomer() {
    if (submitcheck === false) {
      submitcheck = true;
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 Pipeline이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          CustomerApi.deleteCustomerInfo(detailId)
            .then(() => {
              AlertModal("success", "선택하신 Pipeline이 삭제되었습니다.");
              history.push(`/`);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  }

  const refreshData = () => {
    CustomerApi.getCustomerDetailInfo(detailId)
      .then((res) => {
        setProtectionStatus(res.data?.customer?.salesProtection);
        setData(res.data);
        setSalesActivityData({
          ...InitSalesActivityInfo,
          customerId: res.data?.customer.id ? res.data?.customer.id : null,
          title: res.data?.customer.address.placeName
            ? res.data?.customer.address.placeName +
              "_" +
              isoDateFormatter(new Date())
            : "",
        });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
        history.push(`/`);
      });
  };

  const [productFamilyList, setProductFamilyList] = useState<string[]>([]);
  const fetchProductFamily = useCallback(() => {
    ProductApi.searchProductFamily()
      .then((res) => {
        setProductFamilyList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  useEffect(() => {
    fetchProductFamily();
  }, [fetchProductFamily]);

  useEffect(() => {
    if (detailId && myInfoData.email) {
      refreshData();
      window.scrollTo(0, 0);
      // HistoryApi.getHistoryInfo(detailId)
      //   .then((res) => {
      //     setHistoryData(res.data);
      //   })
      //   .catch((err) => {
      //     let msg = ErrorHandler(err);
      //     AlertModal("msg", msg);
      //   });
    }
  }, [detailId, myInfoData.email]);

  // 히스토리 모달
  const [historyData, setHistoryData] = useState<HistoryInfo[]>([]);

  let headerText: any = [
    { text: "상세" },
    { text: "작업 이력" },
    { text: "히스토리" },
  ];

  const onChange = (type: string) => {
    switch (type) {
      case "detail":
        return (
          <LeadDetailMain
            data={data}
            refreshData={refreshData}
            productFamilyList={productFamilyList}
            sasToken={sasToken}
            stepData={stepData}
            refreshActivityData={refreshActivityData}
            IndustryClassificationData={IndustryClassificationData}
            classTeamCenterData={classTeamCenterData}
            intermediaryData={intermediaryData}
            saleTypeData={saleTypeData}
            contactTypeData={contactTypeData}
            contractTypeData={contractTypeData}
            supplyTypeData={supplyTypeData}
            tagData={tagData}
          />
        );
      case "workhistory":
        return (
          <LeadWorkHistory
            data={data}
            refreshActivityData={refreshActivityData}
            activityDataList={activityDataList}
            setActivityDataList={setActivityDataList}
            sortedState={sortedState}
            setSortedState={setSortedState}
          />
        );
      case "history":
        return <LeadHistory historyData={historyData} />;
      default:
        return null;
    }
  };
  // 상세 버튼
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModalClose = () => {
    setAnchorEl(null);
  };

  // delete hover
  const [isDeleteHovered, setIsDeleteHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsDeleteHovered(true);
  };

  const handleMouseLeave = () => {
    setIsDeleteHovered(false);
  };

  const [sasToken, setSasToken] = useState("");

  useEffect(() => {
    if (sasToken === "") {
      TokenApi.getSasToken()
        .then((res) => {
          setSasToken(res.data.sasUrl);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, []);

  // 작업이력
  const [activityDataList, setActivityDataList] = useState<
    SalesActivityDetailInfo[]
  >([]);

  const refreshActivityData = () => {
    if (data?.customer?.id) {
      SalesActivityApi.getSalesActivity(data?.customer?.id)
        .then((res) => {
          setActivityDataList(
            sortedState === "내림차순" ? res.data : res.data.slice().reverse()
          );
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  };

  const [sortedState, setSortedState] = useState<string>("내림차순");

  useEffect(() => {
    refreshActivityData();
  }, [data?.customer?.id, sortedState]);
  const [stepData, setstepData] = useState<DropdownListInfo[]>([]);
  useEffect(() => {
    ListApi.getDropdownList("영업단계")
      .then((res) => {
        setstepData(res.data.filter((item: DropdownListInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  const [salesActivityData, setSalesActivityData] = useState<SalesActivityInfo>(
    InitSalesActivityInfo
  );
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);

    setSalesActivityData({
      ...InitSalesActivityInfo,
      customerId: data?.customer.id ? data?.customer.id : null,
      title: data?.customer.address.placeName
        ? data?.customer.address.placeName + "_" + isoDateFormatter(new Date())
        : "",
    });
  };
  var submitcheck = false;
  function onSave() {
    if (validation(salesActivityData) && submitcheck === false) {
      submitcheck = true;

      SalesActivityApi.createSalesActivity({
        salesActivity: salesActivityData,
        changedcustomerStepId: null,
      })
        .then(() => {
          if (data?.customer?.id) {
            CustomerApi.updateSalesProtection(
              data?.customer?.id,
              !protectionStatus
            )
              .then(() => {
                AlertModal("success", "영업 보호권이 설정되었습니다.");
                setOpen(false);
                setSalesActivityData({
                  ...InitSalesActivityInfo,
                  customerId: data?.customer.id ? data?.customer.id : null,
                  title: data?.customer.address.placeName
                    ? data?.customer.address.placeName +
                      "_" +
                      isoDateFormatter(new Date())
                    : "",
                });
                refreshData();
                refreshActivityData();
              })
              .catch((err) => {
                let msg = ErrorHandler(err);
                AlertModal("msg", msg);
              });
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          submitcheck = false;
        });
    }
  }

  const updateProtection = () => {
    if (protectionStatus) {
      // 작업 이력 생성
      Swal.fire({
        title: "영업보호 설정을 해제하시겠습니까?",
        text: "선택하신 가망고객의 영업보호권이 해제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed && data?.customer?.id) {
          CustomerApi.updateSalesProtection(
            data?.customer?.id,
            !protectionStatus
          )
            .then(() => {
              setProtectionStatus(!protectionStatus);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    } else {
      handleOpen();
    }
  };
  // 업종

  const [IndustryClassificationData, setIndustryClassificationData] = useState<
    DropdownListInfo[]
  >([]);
  useEffect(() => {
    ListApi.getDropdownList("업종구분")
      .then((res) => {
        setIndustryClassificationData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  // 과/팀/센터
  const [classTeamCenterData, setClassTeamCenterData] = useState<
    DropdownListInfo[]
  >([]);
  useEffect(() => {
    ListApi.getDropdownList("과팀센터")
      .then((res) => {
        setClassTeamCenterData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 중개업체명
  const [intermediaryData, setIntermediaryData] = useState<
    IntermediaryCompanyInfo[]
  >([]);

  useEffect(() => {
    CustomerApi.getIntermediaryInfo()
      .then((res) => {
        setIntermediaryData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 수주유형
  const [contractTypeData, setContractTypeData] = useState<DropdownListInfo[]>(
    []
  );

  useEffect(() => {
    ListApi.getDropdownList("수주유형")
      .then((res) => {
        setContractTypeData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 판매방식
  const [saleTypeData, setSaleTypeData] = useState<DropdownListInfo[]>([]);

  useEffect(() => {
    ListApi.getDropdownList("판매방식")
      .then((res) => {
        setSaleTypeData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 공급유형
  const [supplyTypeData, setSupplyTypeData] = useState<DropdownListInfo[]>([]);

  useEffect(() => {
    ListApi.getDropdownList("공급유형")
      .then((res) => {
        setSupplyTypeData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 영업방식
  const [contactTypeData, setContactTypeData] = useState<ContactTypeListInfo[]>(
    []
  );

  useEffect(() => {
    const findTopGroupNameByName = (
      id: number,
      groupData: GroupInfo[]
    ): number | null => {
      for (const item of groupData) {
        if (item.id === id) {
          if (item.parentId) {
            return item.parentId;
          } else {
            return item.id;
          }
        } else if (item.children && item.children.length > 0) {
          const result: number | null = findTopGroupNameByName(
            id,
            item.children
          );
          if (result) {
            return result;
          }
        }
      }

      return null;
    };

    if (
      myInfoData?.groups &&
      myInfoData.groups.length > 0 &&
      myInfoData.groups[0]?.name
    ) {
      const myGroup = myInfoData.groups[0].id;

      GroupApi.getAllGroupInfo()
        .then((res) => {
          if (myGroup) {
            const topGroupId = findTopGroupNameByName(myGroup, res.data);

            if (topGroupId === 2) {
              ListApi.getContactTypeList()
                .then((res) => {
                  setContactTypeData(
                    res.data.filter(
                      (item: ContactTypeListInfo) =>
                        item.branchEditable && item.enabled
                    )
                  );
                })
                .catch((err) => {
                  let msg = ErrorHandler(err);
                  AlertModal("msg", msg);
                });
            } else {
              ListApi.getContactTypeList()
                .then((res) => {
                  setContactTypeData(
                    res.data.filter((item: DropdownListInfo) => item.enabled)
                  );
                })
                .catch((err) => {
                  let msg = ErrorHandler(err);
                  AlertModal("msg", msg);
                });
            }
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    } else {
      ListApi.getContactTypeList()
        .then((res) => {
          setContactTypeData(
            res.data.filter((item: DropdownListInfo) => item.enabled)
          );
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [adminValue, myInfoData.groups]);

  // 태그
  const [tagData, setTagData] = useState<TagListInfo[]>([]);

  useEffect(() => {
    TagApi.getTagList()
      .then((res) => {
        setTagData(res.data.filter((item: DropdownListInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  const calculatedHeight =
    1200 +
    ((data?.attachmentFiles ? data?.attachmentFiles.length : 0) +
      (data?.orders ? data?.orders.length : 0)) *
      57;

  return (
    <>
      <Grid container item xs={12} alignItems="center">
        <Grid item xs={12} sm={6}>
          <CustomText type="title">Pipeline 상세 페이지</CustomText>
        </Grid>
        <Grid container item xs={12} sm={6} justifyContent="right">
          {(myInfoData.id === data?.customer?.seller?.id ||
            myInfoData.id === data?.customer?.group?.manager?.id) && (
            <>
              {protectionStatus ? (
                <CustomButtonPrimary2 onClick={updateProtection}>
                  <CheckIcon />
                  영업 보호권 적용
                </CustomButtonPrimary2>
              ) : (
                <CustomButtonPrimary onClick={updateProtection}>
                  <CheckBoxOutlineBlankIcon />
                  영업 보호권 적용
                </CustomButtonPrimary>
              )}
            </>
          )}

          <IconButton style={{ marginLeft: "5px" }} onClick={handleMenu}>
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorEl)}
            onClick={handleClose}
          >
            <MenuItem
              onClick={deleteCustomer}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              sx={{
                color: isDeleteHovered ? REDTITLE : GRAYPALECONTENTS,
              }}
            >
              <ListItemIcon>
                <DeleteOutlineIcon
                  fontSize="small"
                  sx={{
                    color: isDeleteHovered ? REDTITLE : GRAYPALECONTENTS,
                  }}
                />
              </ListItemIcon>
              <CustomText type="subtitle" bold400 cursorPointer>
                삭제
              </CustomText>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <LeadPageBox $calculatedHeight={calculatedHeight}>
        <TabComponent heightAdjustMode="Auto">
          <TabItemsDirective>
            <TabItemDirective
              header={headerText[0]}
              content={() => onChange("detail")}
            ></TabItemDirective>
            <TabItemDirective
              header={headerText[1]}
              content={() => onChange("workhistory")}
            ></TabItemDirective>
            {adminValue && (
              <TabItemDirective
                header={headerText[2]}
                content={() => onChange("history")}
              ></TabItemDirective>
            )}
          </TabItemsDirective>
        </TabComponent>
      </LeadPageBox>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={BigModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title">작업 이력 생성</CustomText>
              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <MessageComponent
                  severity="Info"
                  content="영업 보호권 설정을 완료하기 위해서는 작업 이력 작성이 필수입니다."
                />
              </Grid>
              <ActivityCreate
                salesActivityData={salesActivityData}
                setSalesActivityData={setSalesActivityData}
                activityModalState="create"
              />

              <ActivityContent
                salesActivityData={salesActivityData}
                setSalesActivityData={setSalesActivityData}
                activityModalState="create"
              />
            </Grid>
            <Grid container item xs={12} justifyContent="right">
              <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default LeadDetailPage;

const CustomButtonPrimary = styled("button")({
  backgroundColor: "#ffffff",
  color: `${PRIMARY}`,
  boxShadow: "none",
  borderRadius: "5px",
  width: "160px",
  height: "2.5rem",
  cursor: "pointer",
  fontWeight: 600,
  border: `1px solid ${PRIMARY}`,
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `${PRIMARYHOVERED}`,
    boxShadow: "none",
    color: "#ffffff",
  },
});

const CustomButtonPrimary2 = styled("button")({
  backgroundColor: `${PRIMARY}`,
  color: "#ffffff",
  boxShadow: "none",
  borderRadius: "5px",
  width: "160px",
  height: "2.5rem",
  cursor: "pointer",
  fontWeight: 600,
  border: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `${PRIMARYHOVERED}`,
    boxShadow: "none",
    color: "#ffffff",
  },
});

const LeadPageBox = styled("div")<{ $calculatedHeight: number }>(
  ({ $calculatedHeight }) => ({
    marginTop: "10px",
    width: "100%",
    height: `${$calculatedHeight}px` || "1200px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  })
);
