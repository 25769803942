import client from "../ApiService";
import HttpClient from "../http-client";
import { CommentInfo } from "../types/MeetingNote";

const baseUri: string = "/comments";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _CommentApi extends _BasicApi {
  getNewComment() {
    return this.client.get(`${baseUri}/new`);
  }
  createComment(dt: CommentInfo) {
    return this.client.post(`${baseUri}`, dt);
  }
  updateComment(commentId: number, dt: CommentInfo) {
    return this.client.put(`${baseUri}/${commentId}`, dt);
  }
  deleteComment(id: number) {
    return this.client.delete(`${baseUri}/${id}`);
  }
}

export const CommentApi = new _CommentApi(client);
