export enum Urls {
  password = "/password",
  login = "/login",
  // 조회
  leadList = "/",
  my = "/list/my",
  myBranch = "/list/mybranch",
  new = "/list/new",
  followUp = "/list/followup",
  answer = "/list/answer",
  leadDetail = "/list/detail/:id",
  intermediary = "/list/intermediary",
  noti = "/list/noti",
  // 등록
  registerByCase = "/register/edit/:id?",
  registerWithExcel = "/register/excel",

  // 게시판
  notice = "/notice",
  noticeEdit = "/notice/edit/:id?",
  noticeDetail = "/notice/detail/:id",
  qna = "/qna",
  qnaEdit = "/qna/edit/:id?",
  qnaDetail = "/qna/detail/:id",
  schedule = "/schedule",
  // 현황
  statusGraph = "/statusGraph",

  // 조달검사검수
  qaqc = "/qaqc",

  // 설정
  myInfo = "/myInfo",

  // 관리자 페이지
  adminUser = "/admin/user",
  adminGroupPermission = "/admin/grouppermission",
  adminGroupPermissionDetail = "/admin/grouppermission/detail/:id?",
  adminInformation = "/admin/information",
  adminProductRegister = "/admin/ProductRegister",
  adminSalesProtection = "/admin/salesprotection",
}
