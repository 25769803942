import { Chip, Grid, IconButton, styled, Tooltip } from "@mui/material";
import { CustomText } from "../../../../../styles/CustomText";
import {
  DisabledImgBox,
  LeadTableCardBox,
  LeadTableContentsBox,
  LeadTableTitlBox,
} from "../../../../../styles/theme";
import { LeadAttachmentFilesInfo } from "../../../../../system/types/Customer";
import { TableLastContentBox } from "../../../../../styles/StatisticsTableStyle";
import uuid from "react-uuid";
import { useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  BLUETITLE,
  GREENCONTENTS,
  REDTITLE,
} from "../../../../../styles/Color";
import { ErrorHandler } from "../../../../../system/ApiService";
import AlertModal from "../../../../../components/Common/AlertModal";
import { AttachmentFileApi } from "../../../../../system/api/AttachmentApi";
import Swal from "sweetalert2";
import { BlobServiceClient } from "@azure/storage-blob";
import AddIcon from "@mui/icons-material/Add";
import { useRecoilState } from "recoil";
import { LoadingAtom } from "../../../../../system/atoms";
import axios, { AxiosError } from "axios";

interface LeadDocProps {
  data: LeadAttachmentFilesInfo[] | null;
  refreshData: () => void;
  customerId: number | null;
  sasToken: string;
}

function LeadDoc({ data, refreshData, customerId, sasToken }: LeadDocProps) {
  function handleDownloadFromStorage(filename: string) {
    // 로컬 스토리지에서 Blob URL 가져오기
    const blobURL = localStorage.getItem("downloadedFile");
    if (!blobURL) {
      console.error("No file downloaded yet.");
      return;
    }
    // 새로운 Blob 생성
    fetch(blobURL)
      .then((response) => response.blob())
      .then((blob) => {
        const newBlob = new Blob([blob], { type: blob.type });
        // Blob을 다운로드
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        // 다운로드가 완료되면 URL 객체를 해제
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error reading file from local storage:", error);
      });
  }

  function handleDownload(filePath: string, url: string, filename: string) {
    const questionMarkIndex = url.indexOf("?");

    if (questionMarkIndex !== -1) {
      fetch(filePath + url.slice(questionMarkIndex))
        .then((response) => response.blob())
        .then((blob) => {
          // Blob을 로컬 스토리지에 저장
          const blobURL = URL.createObjectURL(blob);
          localStorage.setItem("downloadedFile", blobURL);
          // 다운로드 실행
          handleDownloadFromStorage(filename);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    }
  }

  const deleteAttachment = (id: number | null) => {
    if (customerId && id) {
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 제품이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          AttachmentFileApi.deleteAttachmentFile("customers", customerId, id)
            .then(() => {
              AlertModal("success", "선택하신 첨부파일이 삭제되었습니다.");
              refreshData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  };

  const AttachmentKeyId = uuid();

  const UploadFileToBlob = async (file: File[]) => {
    // upload file
    const blobService = new BlobServiceClient(sasToken);
    const containerClient = blobService.getContainerClient("");
    const uploadPromises = file.map((document) => {
      if (document.name === null) {
        return Promise.resolve();
      }

      const blockBlobClient = containerClient.getBlockBlobClient(
        process.env.REACT_APP_ACTIVE_MODE + "/" + document.name
      );

      return blockBlobClient.uploadData(document!);
    });
    return Promise.all(uploadPromises);
  };
  var submitcheck = false;
  const [, setLoadingSpinnerState] = useRecoilState(LoadingAtom);

  const onSaveFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && submitcheck === false && customerId) {
      submitcheck = true;
      setLoadingSpinnerState(true);
      let preFileList: File[] = [];
      let preDataFileList: LeadAttachmentFilesInfo[] = [];
      const attachmentId = AttachmentKeyId;
      for (var i = 0; i < event.target.files.length; i++) {
        const oldFile = event.target.files[i];

        const newFile = new File(
          [oldFile],
          `${attachmentId + "_" + event.target.files[i].name}`,
          {
            type: oldFile.type,
          }
        );
        const oldFilesize = oldFile.size;
        const oldFileType = oldFile.name.split(".").pop();
        const newDataFile = {
          filename: event.target.files[i].name,
          changedName: `${attachmentId}_${oldFile.name}`,
          filePath: `https://inbodyinfra.blob.core.windows.net/inbodylead/${process.env.REACT_APP_ACTIVE_MODE}/${attachmentId}_${oldFile.name}`,
          fileSize: oldFilesize,
          fileType: oldFileType,
        };
        preFileList = preFileList.concat([newFile]);
        preDataFileList = preDataFileList.concat([newDataFile]);
      }
      try {
        await UploadFileToBlob(preFileList);
        await AttachmentFileApi.createAttachmentFile(
          "customers",
          customerId,
          preDataFileList
        );
        AlertModal("success", "첨부파일이 추가되었습니다.");
        refreshData();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const msg = ErrorHandler(err as AxiosError);
          AlertModal("msg", msg);
        } else {
          AlertModal("msg", "예기치 않은 오류가 발생했습니다.");
        }
      } finally {
        submitcheck = false;
        setLoadingSpinnerState(false);
      }
    }
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleChipClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  function formatFileSize(bytes: number): string {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  }

  return (
    <LeadTableCardBox style={{ marginTop: "20px" }}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        padding="10px"
      >
        <CustomText type="subtitle">첨부 파일</CustomText>
        <Grid item>
          <Chip
            variant="outlined"
            color="success"
            size="small"
            label="추가"
            icon={<AddIcon />}
            style={{ cursor: "pointer", marginRight: "5px" }}
            onClick={handleChipClick}
          />
          <DisabledImgBox
            id={"file"}
            type="file"
            ref={fileInputRef}
            onChange={onSaveFile}
            multiple
          ></DisabledImgBox>
        </Grid>
      </Grid>
      <LeadTableContentsBox container item xs={12}>
        {data && data.length > 0 ? (
          <>
            <LeadTableTitlBox container item xs={12} alignItems="center">
              <Grid container justifyContent="center" item xs={4}>
                파일 이름
              </Grid>
              <Grid container justifyContent="center" item xs={2}>
                첨부한 사람
              </Grid>
              <Grid container justifyContent="center" item xs={2}>
                크기
              </Grid>
              <Grid container justifyContent="center" item xs={2}>
                날짜
              </Grid>
              <Grid container justifyContent="center" item xs={2}></Grid>
            </LeadTableTitlBox>
            {data.map((child: LeadAttachmentFilesInfo, index: number) => (
              <TableLastContentBox
                container
                item
                xs={12}
                key={uuid()}
                padding="10px 0px"
                alignItems="center"
              >
                <Grid container justifyContent="center" item xs={4}>
                  {child?.filename}
                </Grid>
                <Grid container justifyContent="center" item xs={2}>
                  {child?.createBy?.name ? child?.createBy?.name : "-"}
                </Grid>
                <Grid container justifyContent="center" item xs={2}>
                  {child?.fileSize ? formatFileSize(child?.fileSize) : "-"}
                </Grid>
                <Grid container justifyContent="center" item xs={2}>
                  {child?.createdAt}
                </Grid>

                <Grid container item xs={2} justifyContent="center">
                  <Tooltip title="다운로드">
                    <DownLoadIconButton
                      onClick={() => {
                        handleDownload(
                          child.filePath,
                          sasToken,
                          child.filename
                        );
                      }}
                    >
                      <DownloadIcon fontSize="small" />
                    </DownLoadIconButton>
                  </Tooltip>
                  <Tooltip title="삭제">
                    <DeleteIconButton
                      onClick={() => {
                        deleteAttachment(child?.id ? child.id : null);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </DeleteIconButton>
                  </Tooltip>
                </Grid>
              </TableLastContentBox>
            ))}
            <Grid
              container
              item
              xs={12}
              justifyContent="right"
              pt="5px"
              pr="10px"
            >
              총 {data.length}개 파일
            </Grid>
          </>
        ) : (
          <Grid container item xs={12} justifyContent="center" margin="20px">
            조회된 데이터가 없습니다.
          </Grid>
        )}
      </LeadTableContentsBox>
    </LeadTableCardBox>
  );
}

export default LeadDoc;

const DownLoadIconButton = styled(IconButton)({
  "&:hover": {
    color: BLUETITLE,
  },
});

const DeleteIconButton = styled(IconButton)({
  "&:hover": {
    color: REDTITLE,
  },
});
