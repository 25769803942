export const EXPECTED_PURCHASE_STAGE = [
  "신규 거래",
  "1개월 이내",
  "3개월 이내",
  "6개월 이내",
  "12개월 이내",
  "장기F/U",
];
export const NOTI_TYPE = [
  { id: "all", name: "전체" },
  { id: "comment", name: "댓글" },
  { id: "meetingNote", name: "미팅노트" },
  { id: "customer", name: "Pipeline" },
  { id: "qna", name: "질문/답변" },
];

export const NOTI_CHECKTYPE = ["전체", "읽음", "안 읽음"];

export const ROLE_TYPE = ["USER", "CS", "ADMIN", "MANAGER", "QAQC"];

export const ENABLED_TYPE = [
  { name: "활성화", value: true },
  { name: "비활성화", value: false },
];

export const QNACLS_TYPE = ["기획", "일정", "기술", "기타"];

export const MONTH_LIST = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const PURCHASEPROBABILITY_TYPE = [
  { id: 0, name: 25 },
  { id: 1, name: 50 },
  { id: 2, name: 75 },
  { id: 3, name: 90 },
];

export const STEP_TYPE = [
  "잠재고객 발굴",
  "고객 접촉",
  "문의 및 제안",
  "협상 및 조율",
  "계약 체결/계약 실패",
  "사후관리",
];

export const SALESACTIVITY_TYPE = [
  "전화",
  "이메일",
  "DM",
  "방문",
  "웨비나",
  "데모",
  "납품",
  "고객 서비스",
  "기타",
];
export const SALESACTIVITY_STATUS = [
  "입력대기",
  "지연",
  "진행",
  "완료",
  "취소",
];

export const SALESACTIVITY_PRIORITY = [
  { id: 1, name: "매우 높음" },
  { id: 2, name: "높음" },
  { id: 3, name: "보통" },
  { id: 4, name: "낮음" },
  { id: 5, name: "매우 낮음" },
];
