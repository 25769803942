import {
  AddressInfo,
  JusoApiInfo,
  LeadCustomerInfo,
  SectionStatesInfo,
} from "../../../../../system/types/Customer";
import { CustomText } from "../../../../../styles/CustomText";
import {
  GRAYPALEBORDER,
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  GREENTITLE,
  REDTITLE,
} from "../../../../../styles/Color";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  DropDownListComponentBox,
  InputLineSmallBox,
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  LeadTableTitleHover,
  TableFixedHeight,
} from "../../../../../styles/theme";
import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import { DropdownListInfo } from "../../../../../system/types/List";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { debounce } from "@mui/material/utils";
import uuid from "react-uuid";
import axios from "axios";
import { ListApi } from "../../../../../system/api/ListApi";
import { ErrorHandler } from "../../../../../system/ApiService";
import AlertModal from "../../../../../components/Common/AlertModal";
import { FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { AddressApi } from "../../../../../system/api/AddressApi";
import { CustomerApi } from "../../../../../system/api/CustomerApi";
interface LeadDetailMainProps {
  data: LeadCustomerInfo | null;
  refreshData: () => void;
  toggleSection: (title: keyof SectionStatesInfo) => void;
  sectionStates: SectionStatesInfo;
  IndustryClassificationData: DropdownListInfo[];
  classTeamCenterData: DropdownListInfo[];
}
interface EditDataProps {
  editState: boolean;
  title: string;
  content: string | DropdownListInfo | AddressInfo | null | undefined;
}

function LeadInfo({
  data,
  refreshData,
  toggleSection,
  sectionStates,
  IndustryClassificationData,
  classTeamCenterData,
}: LeadDetailMainProps) {
  const [isHovered, setIsHovered] = useState(false);
  // title: placeName, name, phone, officeNumber, address, detailedAddress, email, businessType, department
  const [editData, setEditData] = useState<EditDataProps>({
    editState: false,
    title: "",
    content: null,
  });

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openEdit = (type: string) => {
    const existingInfo =
      type === "placeName"
        ? data?.address
        : type === "name"
        ? data?.name
        : type === "phone"
        ? data?.phone
        : type === "officeNumber"
        ? data?.officeNumber
        : type === "address"
        ? data?.address
        : type === "detailedAddress"
        ? data?.address
        : type === "email"
        ? data?.email
        : type === "businessType"
        ? data?.businessType
        : type === "department"
        ? data?.department
        : "";

    setEditData({ editState: true, title: type, content: existingInfo });
  };
  var submitcheck = false;
  const onSave = () => {
    if (submitcheck === false && data?.id && data?.address.id) {
      submitcheck = true;
      if (
        editData.title === "placeName" ||
        editData.title === "address" ||
        editData.title === "detailedAddress"
      ) {
        if (typeof editData.content === "object") {
          AddressApi.updateAddresses(data?.address.id, {
            ...editData.content,
            customerId: data?.id,
          } as AddressInfo)
            .then(() => {
              refreshData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      } else {
        CustomerApi.updateCustomerSingleFieldInfo(
          data.id,
          editData.title,
          typeof editData.content === "string"
            ? { value: editData.content }
            : editData.content
        )
          .then(() => {
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          })
          .finally(() => {
            submitcheck = false;
          });
      }
    }
  };

  const onCancel = () => {
    setEditData({ editState: false, title: "", content: null });
    setDirectInput(false);
  };
  // 상호명
  const [directInput, setDirectInput] = useState(false);
  const onChange = (args: any) => {
    setEditData({ ...editData, content: args.target.value });
  };

  const onChangeDropdown = (args: any) => {
    setEditData({
      ...editData,
      content: args.target.itemData,
    });
  };

  const onChangePlaceName = (args: any) => {
    const placeValue = args.target.value;
    setEditData((prevEditData: any) => {
      // editData.content가 객체 타입인 경우
      return {
        ...prevEditData,
        content: {
          ...prevEditData.content,
          placeName: placeValue,
        },
      };
    });
  };

  const onChangeDetail = (args: any) => {
    const placeValue = args.target.value;
    setEditData((prevEditData: any) => {
      // editData.content가 객체 타입인 경우
      return {
        ...prevEditData,
        content: {
          ...prevEditData.content,
          detail: placeValue,
        },
      };
    });
  };

  // kakao data
  const [options, setOptions] = useState<AddressInfo[]>([]);
  const [value, setValue]: any = useState(null);
  const [inputValue, setInputValue] = useState("");
  const kakaoKey = "b4cfe488cd8ef4b5f7bc9fbcd0f4b055";
  const jusoKey = `${process.env.REACT_APP_ADDRESS_KEY}`;
  const debounceTic = 100;
  const debounceTic2 = 100;
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [jusoValue, setJusoValue]: any = useState(null);
  const [jusoInputValue, setJusoInputValue] = useState("");
  const [jusoOptions, setJusoOptions] = useState<AddressInfo[]>([]);

  // 국가주소시스템 API
  const fetchJuso = useMemo(
    () =>
      debounce(async (jusoValue, active) => {
        if (!active || jusoValue === null || jusoValue === "") {
          setJusoOptions([]);
          return undefined;
        }

        try {
          const response = await axios.get(
            `https://business.juso.go.kr/addrlink/addrLinkApi.do?currentPage=1&countPerPage=100&keyword=${jusoValue}&confmKey=${jusoKey}&resultType=json`
          );
          if (editData.title === "address") {
            const locations = response.data.results?.juso
              ? response.data.results?.juso.map((location: any) => ({
                  admCd: location?.admCd,
                  rn: location?.rn,
                  emdNm: location?.emdNm,
                  zipNo: location?.zipNo,
                  emdNo: location?.emdNo,
                  sggNm: location?.sggNm,
                  engRoad: location?.engAddr,
                  rnMgtSn: location?.rnMgtSn,
                  jibun: location?.jibunAddr,
                  siNm: location?.siNm,
                  bdNm: location?.bdNm,
                  lnbrMnnm: location?.lnbrMnnm,
                  road: location?.roadAddr,
                  lnbrSlno: location?.lnbrSlno,
                  buldMnnm: location?.buldMnnm,
                  liNm: location?.liNm,
                  bdMgtSn: location?.bdMgtSn,
                  buldSlno: location?.buldSlno,
                  category: location?.category,
                }))
              : [];

            setJusoOptions([]);
            setJusoOptions(locations);
          } else {
            const location: JusoApiInfo[] = response.data?.results?.juso;
            const locationData: JusoApiInfo =
              location.length > 0 ? location[0] : {};

            if (locationData) {
              const resultData = {
                placeName: locationData?.bdNm
                  ? locationData?.bdNm
                  : value?.placeName,
                admCd: locationData?.admCd,
                rn: locationData?.rn,
                emdNm: locationData?.emdNm,
                zipNo: locationData?.zipNo,
                emdNo: locationData?.emdNo,
                sggNm: locationData?.sggNm,
                engRoad: locationData?.engAddr,
                rnMgtSn: locationData?.rnMgtSn,
                jibun: locationData?.jibunAddr,
                siNm: locationData?.siNm,
                bdNm: locationData?.bdNm ? locationData?.bdNm : "",
                lnbrMnnm: locationData?.lnbrMnnm,
                road: locationData?.roadAddr ? locationData?.roadAddr : "",
                lnbrSlno: locationData?.lnbrSlno,
                buldMnnm: locationData?.buldMnnm,
                liNm: locationData?.liNm,
                bdMgtSn: locationData?.bdMgtSn,
                buldSlno: locationData?.buldSlno,
              };
            }
          }
        } catch (error) {
          console.error("Error fetching juso location data:", error);
        }
      }, debounceTic2),
    [directInput, value?.placeName, jusoKey]
  );
  const onChangeAddress = (args: any, newValue: any) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    setEditData({ ...editData, content: newValue });
    const searchAddress = newValue?.road;

    let active = true;
    if (inputValue === "") {
      setJusoOptions(jusoValue ? [jusoValue] : []);
      return undefined;
    }
    fetchJuso(searchAddress, active);

    return () => {
      active = false;
    };
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDirectInput(event.target.checked);
    setValue(null);
    setJusoValue(null);
    setJusoOptions([]);
    setOptions([]);

    setEditData({ ...editData, content: data?.address });
  };

  useEffect(() => {
    // Clean up timeout when component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const fetchKakaoLocations = useMemo(
    () =>
      debounce(async (value, active) => {
        if (!active || value === null || value === "") {
          setOptions([]);
          return undefined;
        }
        try {
          const response = await axios.get(
            `https://dapi.kakao.com/v2/local/search/keyword.json?query=${value}`,
            {
              headers: {
                Authorization: `KakaoAK ${kakaoKey}`, // YOUR_KAKAO_API_KEY를 발급받은 키로 대체해주세요.
              },
            }
          );

          const locations = response.data.documents.map((location: any) => ({
            road: !location.road_address_name
              ? location.address_name
              : location.road_address_name,
            old: location.address_name,
            locId: location.id,
            category: location.category_name,
            placeName: location.place_name,
            longitude: location.x,
            latitude: location.y,
            placeUrl: location.place_url,
          }));
          setOptions([]);
          setOptions(locations);
        } catch (error) {
          console.error("Error fetching Kakao location data:", error);
        }
      }, debounceTic),
    [debounceTic]
  );

  useEffect(() => {
    if (editData.title === "address") {
      let active = true;
      if (jusoInputValue === "") {
        setJusoOptions(jusoValue ? [jusoValue] : []);
        return undefined;
      }

      setTimeoutId((prevTimeoutId) => {
        // Cancel the previous timeout
        if (prevTimeoutId) {
          clearTimeout(prevTimeoutId);
        }

        // Set up a new timeout
        const newTimeoutId = setTimeout(() => {
          fetchJuso(jusoInputValue, active);
        }, 300);

        return newTimeoutId;
      });

      return () => {
        active = false;
      };
    }
  }, [jusoValue, jusoInputValue, fetchJuso, editData.title]);

  useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    setTimeoutId((prevTimeoutId) => {
      // Cancel the previous timeout
      if (prevTimeoutId) {
        clearTimeout(prevTimeoutId);
      }

      // Set up a new timeout
      const newTimeoutId = setTimeout(() => {
        fetchKakaoLocations(inputValue, active);
      }, 300);

      return newTimeoutId;
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetchKakaoLocations]);

  const onFiltering = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("name", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  // 업종

  const industryClassficationFields: Object = {
    dataSource: IndustryClassificationData,
    value: "id",
    text: "name",
    child: "children",
  };

  // 과/팀/센터

  const classTeamCenterDataFields: Object = {
    dataSource: classTeamCenterData,
    value: "id",
    text: "name",
    child: "children",
  };

  return (
    <LeadTableCardBox mt="20px">
      <LeadTableTitleHover
        container
        item
        xs={12}
        onClick={() => toggleSection("LeadInfo")}
        justifyContent="space-between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CustomText type="subtitle" lineSpace cursorPointer>
          고객 정보
        </CustomText>
        {isHovered ? (
          <>{sectionStates["LeadInfo"] ? <ExpandLess /> : <ExpandMore />}</>
        ) : (
          ""
        )}
      </LeadTableTitleHover>
      {sectionStates["LeadInfo"] && (
        <LeadTableContentsBox container item xs={12} alignItems="center">
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                lineSpace
                color={GRAYPALECONTENTS}
              >
                상호명
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "placeName" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  {directInput ? (
                    <InputLineSmallBox
                      type="text"
                      name="email"
                      onChange={onChangePlaceName}
                      value={
                        typeof editData.content === "object" &&
                        editData.content !== null &&
                        "placeName" in editData.content
                          ? editData.content.placeName
                          : ""
                      }
                      placeholder="상호명을 입력해 주세요."
                      style={{ margin: "10px 0px 0px 13px" }}
                      autoComplete="new-password"
                    />
                  ) : (
                    <Autocomplete
                      sx={{
                        "& .MuiInputBase-root": {
                          fontFamily: "Noto sans CJK KR",

                          paddingLeft: "3px",
                          "&.MuiInput-root:after": {
                            borderBottom: "2px solid #000000",
                          },
                        },
                      }}
                      getOptionLabel={(option: AddressInfo) =>
                        typeof option === "string"
                          ? value?.placeName
                          : option.placeName
                      }
                      size="small"
                      filterOptions={(x) => x}
                      options={options}
                      autoComplete
                      //   includeInputInList
                      autoSelect
                      filterSelectedOptions
                      value={value ? value : null}
                      isOptionEqualToValue={(
                        option: AddressInfo,
                        value: AddressInfo
                      ) => option.id === value.id}
                      onChange={(event, newValue) => {
                        onChangeAddress(event, newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={uuid()}>
                            <Grid container alignItems="center">
                              <Grid item sx={{ display: "flex", width: 44 }}>
                                <LocationOnIcon
                                  sx={{ color: "text.secondary" }}
                                />
                              </Grid>
                              <Grid
                                item
                                sx={{
                                  width: "calc(100% - 44px)",
                                  wordWrap: "break-word",
                                }}
                              >
                                {option.placeName}

                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {option.road}
                                </Typography>
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          sx={{ width: "90%", margin: "5px 5% 0px 5%" }}
                        />
                      )}
                      noOptionsText={
                        <Grid
                          container
                          justifyContent="center"
                          margin="10px 0px"
                        >
                          <CustomText
                            type="subtitle"
                            textAlign="center"
                            color="#3D4861"
                          >
                            검색 결과가 없습니다.
                          </CustomText>
                        </Grid>
                      }
                    />
                  )}
                </Grid>

                <Grid container item xs={12} justifyContent="right">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={directInput}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <CustomText type="subtitle" bold400 marginTop="-5px">
                          직접 입력
                        </CustomText>
                      }
                    />
                  </FormGroup>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("placeName")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALETITLE}
                  cursorPointer
                >
                  {data?.address?.placeName ? data?.address.placeName : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                고객 담당자
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "name" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <InputLineSmallBox
                    type="text"
                    onChange={onChange}
                    value={
                      (typeof editData.content === "string" &&
                        editData.content) ||
                      ""
                    }
                    placeholder="고객 담당자를 작성해 주세요."
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("name")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.name ? data?.name : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                고객 연락처(핸드폰)
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "phone" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <InputLineSmallBox
                    type="text"
                    onChange={onChange}
                    value={
                      (typeof editData.content === "string" &&
                        editData.content) ||
                      ""
                    }
                    placeholder="고객 연락처(핸드폰)를 작성해 주세요."
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("phone")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.phone ? data?.phone : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                고객 연락처(내선)
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "officeNumber" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <InputLineSmallBox
                    type="text"
                    onChange={onChange}
                    value={
                      (typeof editData.content === "string" &&
                        editData.content) ||
                      ""
                    }
                    placeholder="고객 연락처(내선)를 작성해 주세요."
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("officeNumber")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.officeNumber ? data?.officeNumber : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                고객 주소
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "address" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <Autocomplete
                    size="small"
                    sx={{
                      "& .MuiInputBase-root": {
                        fontFamily: "Noto sans CJK KR",

                        paddingLeft: "3px",
                        "&.MuiInput-root:after": {
                          borderBottom: "2px solid #000000",
                        },
                      },
                    }}
                    getOptionLabel={(option: AddressInfo) =>
                      typeof option === "string" ? option : option.road
                    }
                    filterOptions={(x) => x}
                    options={jusoOptions}
                    autoComplete
                    //   includeInputInList
                    autoSelect
                    filterSelectedOptions
                    value={jusoValue ? jusoValue : null}
                    isOptionEqualToValue={(
                      option: AddressInfo,
                      value: AddressInfo
                    ) => option.id === value.id}
                    onChange={(event, newValue) => {
                      setOptions(
                        newValue ? [newValue, ...jusoOptions] : jusoOptions
                      );
                      setJusoValue(newValue);
                      setEditData({
                        ...editData,
                        content: newValue ? newValue : null,
                      });
                    }}
                    onInputChange={(event, newInputValue) => {
                      setJusoInputValue(newInputValue);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuid()}>
                          <Grid container alignItems="center">
                            <Grid item sx={{ display: "flex", width: 44 }}>
                              <LocationOnIcon
                                sx={{ color: "text.secondary" }}
                              />
                            </Grid>
                            <Grid
                              item
                              sx={{
                                width: "calc(100% - 44px)",
                                wordWrap: "break-word",
                              }}
                            >
                              {option.placeName}
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {option.road}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {option.jibun}
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        sx={{ width: "90%", margin: "5px 5% 0px 5%" }}
                      />
                    )}
                    noOptionsText={
                      <Grid container justifyContent="center" margin="10px 0px">
                        <CustomText
                          type="subtitle"
                          textAlign="center"
                          color="#3D4861"
                        >
                          검색 결과가 없습니다.
                        </CustomText>
                      </Grid>
                    }
                  />
                </Grid>

                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("address")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.address?.road ? data?.address.road : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                상세 주소
              </CustomText>
            </LeadTableTitle>

            {editData.editState && editData.title === "detailedAddress" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <InputLineSmallBox
                    type="text"
                    onChange={onChangeDetail}
                    value={
                      typeof editData.content === "object" &&
                      editData.content !== null &&
                      "detail" in editData.content
                        ? editData.content.detail
                        : ""
                    }
                    placeholder="상세 주소를 작성해 주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("detailedAddress")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.address?.detail ? data?.address.detail : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} alignItems="center">
            <LeadTableTitle item xs={2} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                고객 이메일
              </CustomText>
            </LeadTableTitle>

            {editData.editState && editData.title === "email" ? (
              <Grid
                container
                item
                xs={10}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <InputLineSmallBox
                    type="text"
                    onChange={onChange}
                    value={
                      (typeof editData.content === "string" &&
                        editData.content) ||
                      ""
                    }
                    placeholder="고객 이메일을 작성해 주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("email")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.email ? data?.email : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>

          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                업종
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "businessType" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownListComponentBox
                    locale="ko"
                    showClearButton={true}
                    dataSource={IndustryClassificationData}
                    filtering={(args: any) =>
                      onFiltering(args, IndustryClassificationData)
                    }
                    allowFiltering={true}
                    fields={industryClassficationFields}
                    name="businessType"
                    value={
                      typeof editData.content === "object"
                        ? editData.content?.id
                        : null
                    }
                    onChange={onChangeDropdown}
                    placeholder="업종을 선택해주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("businessType")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.businessType?.name ? data?.businessType?.name : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                과/팀/센터
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "department" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownListComponentBox
                    locale="ko"
                    dataSource={classTeamCenterData}
                    filtering={(args: any) =>
                      onFiltering(args, classTeamCenterData)
                    }
                    allowFiltering={true}
                    fields={classTeamCenterDataFields}
                    name="department"
                    value={
                      typeof editData.content === "object"
                        ? editData.content?.id
                        : null
                    }
                    showClearButton={true}
                    onChange={onChangeDropdown}
                    placeholder="과/팀/센터를 선택해주세요."
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("department")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.department?.name ? data?.department?.name : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
        </LeadTableContentsBox>
      )}
    </LeadTableCardBox>
  );
}

export default LeadInfo;

const LeadTableContentStyled = styled(LeadTableContent)`
  position: relative;
  &:hover {
    cursor: pointer;
    border: 1px solid ${GRAYPALEBORDER};
    border-radius: 8px;
    & .editIcon {
      display: block;
    }
  }
`;

const EditIconStyled = styled(EditIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
`;
