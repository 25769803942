import client from "../ApiService";
import HttpClient from "../http-client";
import {
  LeadAttachmentFilesInfo,
  LeadOrderCreateInfo,
} from "../types/Customer";

const listUri: string = "/attachment-file";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _AttachmentFileApi extends _BasicApi {
  getAttachmentFile(type: string, targetId: string) {
    return this.client.get(`${listUri}/${type}/${targetId}`);
  }
  createAttachmentFile(
    type: string,
    targetId: number,
    data: LeadAttachmentFilesInfo[]
  ) {
    return this.client.post(`${listUri}/${type}/${targetId}`, data);
  }
  deleteAttachmentFile(
    type: string,
    targetId: number,
    attachmentFileId: number
  ) {
    return this.client.delete(
      `${listUri}/${type}/${targetId}/${attachmentFileId}`
    );
  }
}

export const AttachmentFileApi = new _AttachmentFileApi(client);
